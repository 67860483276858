import React from 'react'
import '../Styles/SolutionPageSolution.css'


const SolutionPageSolution = ({
  hybrid,
  solution_img2,
  solution_img3,
  setCard1,
  card1,
  card2,
  card3,
  setCard2,
  setCard3,
}) => {
  return (
    <>
      <div className=" mobile_slider">
        <div
          className={card1 ? "carded_solution_first" : "carded_solution"}
          onClick={() => {
            setCard1(true);
            setCard2(false);
            setCard3(false);
          }}
        >
          <figure>
            <img src={solution_img2} alt="img" />
          </figure>
          <h2>On- Grid System</h2>
          <p>
            We provide FREE energy audit for your homes and offices and FREE
            site survey to determine the suitable solar solution according to
            your site and requirements
          </p>
        </div>
      </div>

      <div className=" mobile_slider">
        <div
          className={card2 ? "carded_solution_second" : "carded_solution"}
          onClick={() => {
            setCard1(false);
            setCard2(true);
            setCard3(false);
          }}
        >
          <figure>
            <img src={hybrid} alt="img" className="img-fluid" />
          </figure>
          <h2 className="second_h2">Semi-Hybrid System</h2>
          <p>
            It will only provide the power to the house and will also charge
            your batteries for backup. Pre-Tailored packeges accourding to your
            need are present but you can also customize them.
          </p>
        </div>
      </div>

      <div className="mobile_slider">
        <div
          className={card3 ? "carded_solution_third" : "carded_solution"}
          onClick={() => {
            setCard1(false);
            setCard2(false);
            setCard3(true);
          }}
        >
          <figure>
            <img src={solution_img3} alt="img" className="img-fluid " />
          </figure>
          <h2>Hybrid System</h2>
          <p>
            This system will provide the power for your house and also charge
            battries for backup as well as the extra energy will be exported to
            the grid. Click here for more details and offers.
          </p>
        </div>
      </div>
    </>
  );
};

export default SolutionPageSolution