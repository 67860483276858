import React from 'react';
import '../Styles/Blogspage.css';
import solution_sekeching_img from "../Images/solution_sekeching_img.png";
import blog_img1 from "../Images/blog_img1.png";
import blog_img2 from "../Images/blog_img2.png";
import blog_img3 from "../Images/blog_img3.png";
import { NavLink } from 'react-router-dom';


const BlogsPage = () => {
  return (
    <>
      {/*start sketching section */}

      <section className="blogspage_section">
        <div className="container">
          <div className="blogs_heading ">
            <h1>
              Our <span className="blogs">Blogs</span>
            </h1>
            <p>
              Since its inception in 2018, GreenWend Energy has been serving the
              people of this region with committment to provide best solar
              products at reasonable prices.{" "}
            </p>
          </div>
        </div>

        {/* secyion */}

        <div className="container grid grid-two-column">
          <div className="blogspage_image " data-aos="fade-down-right">
            <div className="mobile_view_heading">
              <small>20 December 2022 / 4:00pm</small>
              <h2 className="semi_heading_one">
                {" "}
                What is <span className="sketch_design">Semi- Hybrid</span>{" "}
                System?
              </h2>
            </div>
            <figure>
              <img src={solution_sekeching_img} alt="img" className="" />
            </figure>
          </div>
          <div className="blogspage_right" data-aos="fade-down-left">
            <h2 className="semi_heading_one">
              {" "}
              What is <span className="sketch_design">Semi- Hybrid</span> System
            </h2>
            <p>
              To understand the concept of Semi Hybrid Solar Energy System first
              we must understand the concepts of{" "}
              <a href="#" className="links">
                on-grid solar system
              </a>{" "}
              and{" "}
              <a href="#" className="links">
                hybrid solar system
              </a>
              . On-Grid solar systems are connected to your electricity supply
              from grid.
            </p>
            <NavLink to="/blogdetail" className="read_more">
              READ MORE <i className="fa fa-arrow-right"></i>
            </NavLink>
          </div>
        </div>
      </section>
      {/*end sketching section */}

      <div className="blog">
        <div className="line"></div>
      </div>

      <section className="blogspage_section1">
        <div className="container grid grid-two-column">
          <div className="blogspage_right1" data-aos="fade-down-right">
            <h2 className="semi_heading_two">
              {" "}
              What is <span className="sketch_design">Semi- Hybrid</span>{" "}
              System?
            </h2>
            <p>
              To understand the concept of Semi Hybrid Solar Energy System first
              we must understand the concepts of{" "}
              <a href="#" className="links">
                on-grid solar system
              </a>{" "}
              and{" "}
              <a href="#" className="links">
                hybrid solar system
              </a>
              . On-Grid solar systems are connected to your electricity supply
              from grid.
            </p>

            <NavLink to="/blogdetail" className="read_more">
              READ MORE <i className="fa fa-arrow-right"></i>
            </NavLink>
          </div>
          <div className="blogspage_image1 " data-aos="fade-down-left">
            <div className="mobile_view_heading">
              <small>20 December 2022 / 4:00pm</small>
              <h2 className="semi_heading_one">
                {" "}
                What is <span className="sketch_design">Semi- Hybrid</span>{" "}
                System?
              </h2>
            </div>
            <figure>
              <img src={solution_sekeching_img} alt="img" className="" />
            </figure>
          </div>
        </div>
      </section>
      {/*end sketching section */}

      {/*start sketching section */}

      <section className="blogspage_section2">
        <div className="container grid grid-two-column">
          <div className="blogspage_image2 " data-aos="fade-down-right">
            <div className="mobile_view_heading">
              <small>20 December 2022 / 4:00pm</small>
              <h2 className="semi_heading_one">
                {" "}
                What is <span className="sketch_design">Semi- Hybrid</span>{" "}
                System?
              </h2>
            </div>
            <figure>
              <img src={solution_sekeching_img} alt="img" className="" />
            </figure>
          </div>
          <div className="blogspage_right2" data-aos="fade-down-left">
            <h2 className="semi_heading_three">
              {" "}
              What is <span className="sketch_design">Semi- Hybrid</span>{" "}
              System?
            </h2>
            <p>
              To understand the concept of Semi Hybrid Solar Energy System first
              we must understand the concepts of{" "}
              <a href="#" className="links">
                on-grid solar system
              </a>{" "}
              and{" "}
              <a href="#" className="links">
                hybrid solar system
              </a>
              . On-Grid solar systems are connected to your electricity supply
              from grid.
            </p>
            <NavLink to="/blogdetail" className="read_more">
              READ MORE <i className="fa fa-arrow-right"></i>
            </NavLink>
          </div>
        </div>
      </section>
      {/*end sketching section */}
    </>
  );
}

export default BlogsPage