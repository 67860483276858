import React from 'react'
import narada from "../Images/narada.png";
import ritar from "../Images/ritar.jpg";
import Pylontech from "../Images/Pylontech.jpg";
import SES from "../Images/SES.jpg";
import tesla_batteries from "../Images/tesla_batteries.webp";
import osala4 from "../Images/osala4.jpg";
import phoinix from "../Images/phoinix.jpg";
import volta from "../Images/volta.jpg";

const Battries = () => {
  return (
    <>
      {" "}
      <div className="hybrid_ongrid_section">
        <div className="container mt-24">
          <h2>Dry Batteries</h2>
        </div>
      </div>
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={narada} alt="img" className="img-fluid" />
            </figure>
          </div>
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>NARADA </h3>
                <ul>
                  <li>12V, 100AH</li>
                  <li>Maximum charging limit: 25A</li>
                  <li>M Normal Voltage: 12V</li>
                  <li>60000 (100ah)</li>
                  <li>Weight: 61kg</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>Ritar</h3>
                <ul>
                  <li> 12V 200Ah Deep Cycle Hybrid Gel Battery</li>
                  <li>Terminal – F10(M8)/F16(M8)</li>
                  <li>Design Life – 12 years (floating charge)</li>
                  <li>6Maximum Charging Current – 60.0 A</li>
                  <li>Discharge Current 1000A (5 sec) </li>
                  <li>Container Material</li>
                  <li>Float charging Voltage 13.6 to 13.8 VDC/unit</li>
                  <li> Average at 25 C </li>
                  <li>Internal Resistance 5mC2 </li>
                  <li>Weight 30.0 Kg </li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={ritar} alt="img" className="img-fluid" />
            </figure>
          </div>
        </div>
      </section>
      {/*  */}
      <div className="hybrid_ongrid_section">
        <div className="container mt-24">
          <h2>Lithium Ion Batteries</h2>
        </div>
      </div>
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={Pylontech} alt="img" className="img-fluid" />
            </figure>
          </div>
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>PYLONTECH US5000 LITHIUM ION BATTERY </h3>
                <ul>
                  <li> 4.8kWh lithium battery with built in BMS</li>
                  <li>compatible with the 35 solar inverters</li>
                  <li>4.8KWH 48V 100AH</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>SES Power specs</h3>
                <ul>
                  <li> Model: 51NPFC100</li>
                  <li>100A Series</li>
                  <li>48V</li>
                  <li>LCD </li>
                  <li>Nominal voltage 51.2 V </li>
                  <li>Case: Wall Mounted</li>
                  <li>Charge Current: 20A 50A </li>
                  <li>Cycle Life: 5000+ Cycles</li>
                  <li> Nominal Capacity 100 Ah </li>
                  <li>Weight 54 Kg </li>
                  <li>Dimension 600 * 240 * 210 mm </li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={SES} alt="img" className="img-fluid" />
            </figure>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={tesla_batteries} alt="img" className="img-fluid" />
            </figure>
          </div>
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>
                  The 13.5 kWh-capacity Tesla Powerwall is a rechargeable
                  battery
                </h3>
                <ul>
                  <li> Model: TLI-52100X</li>
                  <li>48V</li>
                  <li>4Use 95% of battery capacity</li>
                  <li>Fast charging </li>
                  <li>Can be installed on the floor</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <div className="hybrid_ongrid_section">
        <div className="container mt-24">
          <h2>Tabular Batteries</h2>
        </div>
      </div>
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={osala4} alt="img" className="img-fluid" />
            </figure>
          </div>
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>Osaka Tubular Deep Cycle Battery </h3>
                <ul>
                  <li> Capacity @ 20 HR: 185 Ah</li>
                  <li>Number of Plates: 5</li>
                  <li>Weight : 54.9</li>
                  <li>Cycle Used: 14.4V ~14.7V</li>
                  <li>Stand by Used: 13.8V ~14.2V</li>
                  <li>good low temperature </li>
                  <li>super long shelf life </li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>Phoenix TX 3500 Tubular Battery</h3>
                <ul>
                  <li> Plates Per Cell : 9</li>
                  <li>20 AH</li>
                  <li>Dry Weight: 77 (Kg)</li>
                  <li>Polarity C</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={phoinix} alt="img" className="img-fluid" />
            </figure>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={volta} alt="img" className="img-fluid" />
            </figure>
          </div>
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>VOLTA SPECS</h3>
                <ul>
                  <li>Volts 12</li>
                  <li>Plates per cell 23</li>
                  <li>Ampere 155Ah</li>
                  <li>Size in mm L-505 x W-222 x H-255 (+/-5mm)</li>
                  <li>1 year warranty</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Battries