import React from 'react'
import Index from './Projects/Index'
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const App = () => {
  return (
    <div>
      <Index />  
    </div>
  )
}

export default App