import React,{useState} from "react";
import "../Styles/Offering.css";
import solution_img1 from "../Images/solution_img1.png";
import solution_img2 from "../Images/solution_img2.png";
import ServicsSolution from "../Components/OfferingSolution";
import ServiceProducts from "../Components/OfferingProducts";
import OfferingWork from "../Components/OfferingWork";
import solution_img3 from "../Images/solution_img3.png";
import products_img1 from "../Images/products_img1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import products1_img from "../Images/products1_img.png";
import product2_img from "../Images/product2_img.png";
import portfolio1 from "../Images/portfolio1.jpg";
import portfolio2 from "../Images/portfolio2.jpg";
import portfolio3 from "../Images/portfolio3.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { useNavigate } from "react-router-dom";

const Service = () => {

const [product1, setProduct1] = useState(true);
const [product2, setProduct2] = useState(false);
const [product3, setProduct3] = useState(false);

const navigate = useNavigate();

  return (
    <>
      <section className="service_hero_section">
        <div className="container">
          <div className="service_hero_left">
            <h1>Our</h1>
            <h2>Offering</h2>
            <p>
              Since its inception in 2018, GreenWend Energy has been serving the
              people of this region with committment to provide best solar
              products at reasonable prices. Our rise to be the top solar energy
              company in the region is a reflection of how we take customer's
              satisfaction seriously. Over the short span of 4 years, we have a
              total installed capacity of more than 6 Mega Watts both in public
              and private sectors. "
            </p>
          </div>
        </div>
      </section>
      {/* portfolio section */}
      <section>
        <div className="our_portfolio_section mb-7">
          <div className="container">
            <div className="portfolio_heading mt-32"></div>
          </div>
          <div className="container ">
            <div className="row flex-column-reverse flex-lg-row mt-8 justify-content-center align-items-center">
              <div
                className="col-lg-6 col-md-12 col-12 portfolio_left_section_offering"
                data-aos="fade-down-right"
              >
                <div className="portfolio_img ">
                  <img src={portfolio1} alt="img" className="img-fluid" />
                  <img
                    src={portfolio2}
                    alt="img"
                    className="img-fluid mt-2"
                  />
                </div>
                <div className="portfolio_img1 mt-20 ml-2">
                  <img src={portfolio3} alt="img" className="img-fluid" />
                </div>
              </div>

              <div
                className="col-lg-6 col-md-12 col-12 portfolio_right_main "
                data-aos="fade-down-left"
              >
                <div className="portfolio_right ml-6">
                  <p className="offering_portfolio_p">Our Services </p>
                  <h2>
                    Real-Life Example of{" "}
                    <span className="fw-bold">GreenWendEnergy</span> Project
                  </h2>
                  <p>
                    GreenWend Energy installed a 10 kW Hybrid system at DHA
                    Peshawar for the former Chief of the State Bank of Pakistan.
                    We installed 18 panels of 545 watts and two 6Kw Fronius
                    Inverters. This is one of our earlier projects that took
                    place in 2021. This system is designed to provide clean and
                    sustainable energy for the community, while also reducing
                    dependence on traditional fossil fuels. Our hybrid system is
                    a combination of solar panels and a backup generator,
                    ensuring that the community always has access to power, even
                    during outages or periods of low sunlight. The 10 kW
                    capacity of the system is enough to power multiple homes and
                    businesses in the area. In addition to providing a reliable
                    source of energy, this project will also help to reduce
                    carbon emissions and improve air quality in the area. It's a
                    win-win for the community and the environment
                  </p>
                  <button
                    onClick={() => {
                      navigate("/services");
                    }}
                  >
                    {" "}
                    GET OUR SERVICES <i className="fa fa-arrow-right "></i>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="solution desktop_view_offering_cards">
        <div className="container w-75 ">
          <div className="solution_heading">
            <h2>
              Our <span className="fw-bold solution">Solution</span>
            </h2>
            <p>
              We are happy to use our expertise to work with you to develop a
              tailor-made solution for your challenge. Here are some highlights
              of our range of our offerings.
            </p>
          </div>
        </div>

        <div className="container d-flex justify-content-center align-items-center">
          <div className="rows d-flex gap-5">
            <ServicsSolution
              solution_img1={solution_img1}
              solution_img2={solution_img2}
              solution_img3={solution_img3}
            />
          </div>
        </div>
      </section>

      {/* for mobile view */}
      <section className="solution mobile_view_offering_cards">
        <div className="container w-75 ">
          <div className="solution_heading">
            <h2>
              Our <span className="fw-bold solution">Solution</span>
            </h2>
            <p>
              We are happy to use our expertise to work with you to develop a
              tailor-made solution for your challenge. Here are some highlights
              of our range of our offerings.
            </p>
          </div>
        </div>

        <div className="container">
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            centeredSlides={true}
            className="mySwiper"
            loop={false}
            showsPagination={false}
            // pagination={{
            //   clickable: true,
            // }}
            // modules={[Pagination]}
            // className="mySwiper"
          >
            <SwiperSlide className="margin-left">
              {" "}
              <div className="mobile_slider">
                <div className="carding carding_first">
                  <figure>
                    <img src={solution_img2} alt="img" />
                  </figure>
                  <h2>On- Grid System</h2>
                  <p>
                    We provide FREE energy audit for your homes and offices and
                    FREE site survey to determine the suitable solar solution
                    according to your site and requirements
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="margin-left">
              {" "}
              <div className="mobile_slider">
                <div className="carding carding_second">
                  <figure>
                    <img src={solution_img1} alt="img" className="img-fluid" />
                  </figure>
                  <h2 className="second_h2">Semi-Hybrid System</h2>
                  <p>
                    It will only provide the power to the house and will also
                    charge your batteries for backup. Pre-Tailored packeges
                    accourding to your need are present but you can also
                    customize them.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="margin-left">
              <div className=" mobile_slider">
                <div className="carding carding_third">
                  <figure>
                    <img src={solution_img3} alt="img" className="img-fluid " />
                  </figure>
                  <h2>Hybrid System</h2>
                  <p>
                    This system will provide the power for your house and also
                    charge battries for backup as well as the extra energy will
                    be exported to the grid. Click here for more details and
                    offers.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      {/* service work section */}
      <OfferingWork />
      {/* service work section */}
      <section className="products desktop_view_offering_cards">
        <div className="container ">
          <div className="products_heading mt-16">
            <h2>
              Our <span className="fw-bold products">Products</span>
            </h2>
            <p>
              We are happy to use our expertise to work with you to develop a
              tailor-made solution for your challenge. Here are some highlights
              of our range of our offerings.
            </p>
          </div>
        </div>

        <div className="container d-flex justify-content-center align-items-center ">
          <div className="rows d-flex gap-5 mb-16 ">
            <ServiceProducts
              solution_img1={solution_img1}
              solution_img2={solution_img2}
              product1={product1}
              product2={product2}
              product3={product3}
              setProduct1={setProduct1}
              setProduct2={setProduct2}
              setProduct3={setProduct3}
            />
          </div>

          {/* for mobile view */}
        </div>
      </section>

      <section className="products mobile_view_offering_cards">
        <div className="container ">
          <div className="products_heading mt-16">
            <h2>
              Our <span className="fw-bold products">Products</span>
            </h2>
            <p>
              We are happy to use our expertise to work with you to develop a
              tailor-made solution for your challenge. Here are some highlights
              of our range of our offerings.
            </p>
          </div>
        </div>
        <div className="mobile_view_offering_cards">
          <div className="container">
            {" "}
            <Swiper
              slidesPerView={2}
              spaceBetween={10}
              centeredSlides={true}
              loop={false}
              showsPagination={false}
              className="mySwiper"
              // pagination={{
              //   clickable: true,
              // }}
              // modules={[Pagination]}
              // className="mySwiper "
            >
              <SwiperSlide>
                {" "}
                <div
                  className=" offering_mobile_slider"
                  onClick={() => {
                    navigate("/products");
                  }}
                >
                  <div
                    className={product1 ? "carde_first" : "carde"}
                    onClick={() => {
                      setProduct1(true);
                      setProduct2(false);
                      setProduct3(false);
                    }}
                  >
                    <figure>
                      <img
                        src={products_img1}
                        alt="img"
                        className="images_products"
                      />
                    </figure>
                    <h2>Solar Panales</h2>
                    <p>
                      We provide FREE energy audit for your homes and offices
                      and FREE site survey to determine the suitable solar
                      solution according to your site and requirements
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="offering_mobile_slider"
                  onClick={() => {
                    navigate("/products");
                  }}
                >
                  <div
                    className={product2 ? "carde_first" : "carde"}
                    onClick={() => {
                      setProduct1(false);
                      setProduct2(true);
                      setProduct3(false);
                    }}
                  >
                    <figure>
                      <img
                        src={products1_img}
                        alt="img"
                        className="img-fluid"
                      />
                    </figure>
                    <h2 className="second_h2">Solar Inverters</h2>
                    <p>
                      It will only provide the power to the house and will also
                      charge your batteries for backup. Pre-Tailored packeges
                      accourding to your need are present but you can also
                      customize them.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className=" offering_mobile_slider"
                  onClick={() => {
                    navigate("/products");
                  }}
                >
                  <div
                    className={product3 ? "carde_first" : "carde"}
                    onClick={() => {
                      setProduct1(false);
                      setProduct2(false);
                      setProduct3(true);
                    }}
                  >
                    <figure>
                      <img
                        src={product2_img}
                        alt="img"
                        className="img-fluid images_products"
                      />
                    </figure>
                    <h2>Solar Batteries</h2>
                    <p>
                      This system will provide the power for your house and also
                      charge battries for backup as well as the extra energy
                      will be exported to the grid. Click here for more details
                      and offers.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
