import React from "react";
import { useNavigate } from "react-router-dom";
const NewFooter = () => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate("/");
      }}
    >
      NewFooter
    </div>
  );
};

export default NewFooter;
