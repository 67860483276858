import React, { useState } from "react";
import "../Styles/ProductPage.css";
import solution_img1 from "../Images/solution_img1.png";
import solution_img3 from "../Images/solution_img3.png";
import solution_img2 from "../Images/solution_img2.png";
import ProductsCards from "../Components/ProductsCards";
import { NavLink } from "react-router-dom";
import products_img1 from "../Images/products_img1.png";
import products_img2 from "../Images/products_img2.png";
import products_img3 from "../Images/products_img3.png";
import products1_img from "../Images/products1_img.png";

import product2_img from "../Images/product2_img.png";

import { Swiper, SwiperSlide } from "swiper/react";
import Battries from "../Components/Battries";
import portfolio1 from "../Images/portfolio1.jpg";
import portfolio2 from "../Images/portfolio2.jpg";
import portfolio3 from "../Images/portfolio3.jpg";
import portfolio4 from "../Images/portfolio4.jpg";
import portfolio5 from "../Images/portfolio5.jpg";
import portfolio6 from "../Images/portfolio6.jpg";
import portfolio7 from "../Images/portfolio7.jpg";
import portfolio8 from "../Images/portfolio8.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import Inverter from "../Components/Inverter";
import SolarPanels from "../Components/SolarPanels";

const ProductsPage = () => {
  const [product1, setProduct1] = useState(true);
  const [product2, setProduct2] = useState(false);
  const [product3, setProduct3] = useState(false);

  return (
    <>
      {" "}
      <section className="productpage_hero_section">
        <div className="container ">
          <div className="productpage_hero_left">
            <h1>Our</h1>
            <h2>Services</h2>
            <p>
              Since its inception in 2018, GreenWend Energy has been serving the
              people of this region with committment to provide best solar
              products at reasonable prices. Our rise to be the top solar energy
              company in the region is a reflection of how we take customer's
              satisfaction seriously. Over the short span of 4 years, we have a
              total installed capacity of more than 6 Mega Watts both in public
              and private sectors. "
            </p>
          </div>
        </div>
      </section>
      {/*  products cards*/}
      <section className="products desktop_view_offering_cards">
        <div className="container mt-5">
          <div className="products_heading mt-5">
            <h2>
              <span className="fw-bold products">Products</span>
            </h2>
            <p>
              We are happy to use our expertise to work with you to develop a
              tailor-made solution for your challenge. Here are some highlights
              of our range of our offerings.
            </p>
          </div>
        </div>

        <div className="container d-flex justify-content-center align-items-center">
          <div className="rows d-flex ">
            <ProductsCards
              solution_img1={solution_img1}
              solution_img2={solution_img2}
              solution_img3={solution_img3}
              product1={product1}
              product2={product2}
              product3={product3}
              setProduct1={setProduct1}
              setProduct2={setProduct2}
              setProduct3={setProduct3}
            />
          </div>
        </div>
      </section>
      {/*  products cards*/}
      <section className="products mobile_view_offering_cards">
        <div className="container mt-5">
          <div className="products_heading mt-5">
            <h2>
              <span className="fw-bold products">Products</span>
            </h2>
            <p>
              We are happy to use our expertise to work with you to develop a
              tailor-made solution for your challenge. Here are some highlights
              of our range of our offerings.
            </p>
          </div>
        </div>{" "}
        <div className="container">
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            centeredSlides={true}
            loop={false}
            showsPagination={false}
            className="mySwiper"
          >
            <SwiperSlide>
              {" "}
              <div className="  mobile_slider">
                <div
                  className={product1 ? "carde_first" : "carde"}
                  onClick={() => {
                    setProduct1(true);
                    setProduct2(false);
                    setProduct3(false);
                  }}
                >
                  <figure>
                    <img
                      src={products_img1}
                      alt="img"
                      className="images_products"
                    />
                  </figure>
                  <h2>Solar Panales</h2>
                  <p>
                    We provide FREE energy audit for your homes and offices and
                    FREE site survey to determine the suitable solar solution
                    according to your site and requirements
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className=" mobile_slider">
                <div
                  className={product2 ? "carde_second" : "carde"}
                  onClick={() => {
                    setProduct1(false);
                    setProduct2(true);
                    setProduct3(false);
                  }}
                >
                  <figure>
                    <img src={products1_img} alt="img" className="img-fluid" />
                  </figure>
                  <h2 className="second_h2">Solar Inverters</h2>
                  <p>
                    It will only provide the power to the house and will also
                    charge your batteries for backup. Pre-Tailored packeges
                    accourding to your need are present but you can also
                    customize them.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className=" mobile_slider">
                <div
                  className={product3 ? "carde_third" : "carde"}
                  onClick={() => {
                    setProduct1(false);
                    setProduct2(false);
                    setProduct3(true);
                  }}
                >
                  <figure>
                    <img
                      src={product2_img}
                      alt="img"
                      className="img-fluid images_products"
                    />
                  </figure>
                  <h2>Solar Batteries</h2>
                  <p>
                    This system will provide the power for your house and also
                    charge battries for backup as well as the extra energy will
                    be exported to the grid. Click here for more details and
                    offers.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      {/* Canadian solar section */}
      {product1 && (
        <><SolarPanels/>
        </>
      )}
      {product2 && (
        <>
          <Inverter />
        </>
      )}
      {product3 && <Battries />}
      {/* Products Page Portfolio section */}
      {/* portfolio section */}
      <section>
        <div className="our_portfolio_section mb-7">
          <div className="container">
            <div className="portfolio_heading mt-24">
              <h1>Portfolio</h1>
              <p>Solar System Design </p>
            </div>
          </div>
          <div className="container ">
            <div className="row flex-column-reverse flex-lg-row mt-8 justify-content-center align-items-center">
              <div
                className="col-lg-6 col-md-6 col-12 portfolio_left_section"
                data-aos="fade-down-right"
              >
                <div className="portfolio_img ">
                  <img src={portfolio1} alt="img" className="img-fluid" />
                  <img src={portfolio2} alt="img" className="img-fluid" />
                </div>
                <div className="portfolio_img1 mt-20 ml-2">
                  <img src={portfolio3} alt="img" className="img-fluid" />
                </div>
              </div>

              <div
                className="col-lg-6 col-md-6 col-12 portfolio_right_main "
                data-aos="fade-down-left"
              >
                <div className="portfolio_right ml-6">
                  <h2>
                    10kW System at{" "}
                    <span className="fw-bold">
                      {" "}
                      <br /> DHA Peshawar.
                    </span>
                  </h2>
                  <p>
                    GreenWend Energy installed a 10 kW Hybrid system at DHA
                    Peshawar for the former Chief of the State Bank of Pakistan.
                    We installed 18 panels of 545 watts and two 6Kw Fronius
                    Inverters. This is one of our earlier projects that took
                    place in 2021. This system is designed to provide clean and
                    sustainable energy for the community, while also reducing
                    dependence on traditional fossil fuels. Our hybrid system is
                    a combination of solar panels and a backup generator,
                    ensuring that the community always has access to power, even
                    during outages or periods of low sunlight. The 10 kW
                    capacity of the system is enough to power multiple homes and
                    businesses in the area. In addition to providing a reliable
                    source of energy, this project will also help to reduce
                    carbon emissions and improve air quality in the area. It's a
                    win-win for the community and the environment
                  </p>
                  <button>
                    <NavLink to="/portfoliodetail" className="read_more">
                      READ MORE <i className="fa fa-arrow-right"></i>
                    </NavLink>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="photo_gallary mt-5 mb-5">
        <div className="container justify-content-start">
          <h1 className="photo_gallary_h1">Photo Gallary</h1>
        </div>
        <div className="container">
          <div className="mobile_portfolio_gallary_section">
            <img src={portfolio1} alt="img" />
            <img src={portfolio2} alt="img" />
            <img src={portfolio3} alt="img" />
            <img src={portfolio4} alt="img" />

            <img src={portfolio5} alt="img" />
            <img src={portfolio6} alt="img" />
            <img src={portfolio7} alt="img" />
            <img src={portfolio8} alt="img" />
          </div>
        </div>
      </div>
      {/* portfolio section */}
      {/* Products Page Portfolio section */}
    </>
  );
};

export default ProductsPage;
