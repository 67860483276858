import React from 'react'
import CANADIAN from "../Images/CANADIAN.jpg";
import longi from "../Images/longi.jpg";
import Jinko from "../Images/Jinko.jpg";
import ja from "../Images/ja.jpg";
import Diamond from "../Images/Diamond.png";

const SolarPanels = () => {
  return (
    <>
      {" "}
      <section className="productpage_canadian_section">
        <div className="container grid grid-two-column">
          <div
            className="productpage_canadian_image "
            data-aos="fade-down-right"
          >
            <figure>
              <img src={CANADIAN} alt="img" className="main_image" />
            </figure>
          </div>
          <div className="productpage_canadian_right" data-aos="fade-down-left">
            <h2>
              {" "}
              <span className="sketch_design">Canadian Solar Panel </span> 545
              Watt Mono Perc Half Cut
            </h2>
            <div className="longi_micro_crack mt-4">
              <div>
                <ul>
                  <li>Minimizes microcrack impact</li>
                  <li>Better shading tolerance</li>
                  <li>Cell Technology: polycrystalline and monocrystalline</li>
                  <li>Efficiency: 15% to 19.5%</li>
                  <li>Power Output: 250 watts to 400 watts per panel.</li>
                  <li>Weight: 42 to 47 pounds.</li>
                  <li>Temperature Coefficient: -0.37%/°C.</li>
                  <li>Warranty: 25-year linear power output warranty.</li>
                </ul>
              </div>
            </div>

            <div className="canadian_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>
      {/* Canadian solar section */}
      {/* longi solar section */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <h2>
              {" "}
              <span className="sketch_design">JA Solar </span> Panels
            </h2>
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>Available in 540 and 545 Watts</h3>
                <ul>
                  <li>Power output: 270 watts to 400 watts.</li>
                  <li>Efficiency: 18% and 21%.</li>
                  <li>Cell type: monocrystalline or polycrystalline cells.</li>
                  <li>Weight: 20 kg.</li>
                  <li>Maximum Power Voltage(Vmp) [V]: 41.80</li>
                  <li>
                    Temperature range: Operates in a temperature range of -40°C
                    to +85°C.
                  </li>
                  <li>Warranty: 25-year linear power output warranty.</li>
                </ul>
              </div>
            </div>
            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={ja} alt="img" className="" />
            </figure>
          </div>
        </div>
      </section>
      {/* longi solar section */}
      {/* jonks jinka section */}
      <section className="productpage_canadian_section">
        <div className="container grid grid-two-column mb-5 mt-5">
          <div
            className="productpage_canadian_image "
            data-aos="fade-down-right"
          >
            <figure>
              <img src={Jinko} alt="img" className="" />
            </figure>
          </div>
          <div className="productpage_canadian_right" data-aos="fade-down-left">
            <h2>
              {" "}
              <span className="sketch_design">Jinko Solar </span> Panels
            </h2>
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>Watts: 540 and 545</h3>
                <ul>
                  <li>Jinko Solar is a Tier 1 brand from China. </li>
                  <li>
                    Jinko Solar is ranked fifth-best (#5) overall by the
                    SolarReviews solar panel scoring system.
                  </li>
                  <li>Warranty: 25 years</li>
                  <li>High quality and affordable.</li>
                </ul>
              </div>
            </div>
            <div className="canadian_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>
      {/* Canadian jinka section */}
      {/* ja solar section */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <h2>
              {" "}
              <span className="sketch_design">Longi Solar </span> Panels
            </h2>
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>Longi specs</h3>
                <ul>
                  <li>Watts: 540 and 545 </li>
                  <li>Efficiency: 18.60%</li>
                  <li>Weight: 58.42 lbs</li>
                  <li>Cells: Mono and poly Cells</li>
                  <li>Linear power output warranty:25 years</li>
                  <li>Product Warranty: 12 years</li>
                </ul>
              </div>
            </div>
            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={longi} alt="img" className="" />
            </figure>
          </div>
        </div>
      </section>
    </>
  );
}

export default SolarPanels