import React from 'react'
import '../Styles/Products.css'
import products_img1 from '../Images/products_img1.png';
import products_img2 from "../Images/products_img2.png";
import products_img3 from "../Images/products_img3.png";
import products1_img from "../Images/products1_img.png";

import product2_img from "../Images/product2_img.png";
import { useNavigate } from 'react-router-dom';

const OfferingProducts = ({
  product1,
  product2,
  product3,
  setProduct1,
  setProduct2,
  setProduct3,
}) => {

  
const navigate = useNavigate();

  return (
    <>
      <div
        className="col-4 gx-5 offering_mobile_slider"
        onClick={() => {
          navigate("/products");
        }}
      >
        <div
          className={product1 ? "carde_first" : "carde"}
          onClick={() => {
            setProduct1(true);
            setProduct2(false);
            setProduct3(false);
          }}
        >
          <figure>
            <img src={products_img1} alt="img" className="images_products" />
          </figure>
          <h2>Solar Panales</h2>
          <p>
            We provide FREE energy audit for your homes and offices and FREE
            site survey to determine the suitable solar solution according to
            your site and requirements
          </p>
        </div>
      </div>

      <div
        className="col-4 gx-5 offering_mobile_slider"
        onClick={() => {
          navigate("/products");
        }}
      >
        <div
          className={product2 ? "carde_first" : "carde"}
          onClick={() => {
            setProduct1(false);
            setProduct2(true);
            setProduct3(false);
          }}
        >
          <figure>
            <img src={products1_img} alt="img" className="img-fluid" />
          </figure>
          <h2 className="second_h2">Solar Inverters</h2>
          <p>
            It will only provide the power to the house and will also charge
            your batteries for backup. Pre-Tailored packeges accourding to your
            need are present but you can also customize them.
          </p>
        </div>
      </div>

      <div
        className="col-4 gx-5 offering_mobile_slider"
        onClick={() => {
          navigate("/products");
        }}
      >
        <div
          className={product3 ? "carde_first" : "carde"}
          onClick={() => {
            setProduct1(false);
            setProduct2(false);
            setProduct3(true);
          }}
        >
          <figure>
            <img
              src={product2_img}
              alt="img"
              className="img-fluid images_products"
            />
          </figure>
          <h2>Solar Batteries</h2>
          <p>
            This system will provide the power for your house and also charge
            battries for backup as well as the extra energy will be exported to
            the grid. Click here for more details and offers.
          </p>
        </div>
      </div>
    </>
  );
};

export default OfferingProducts