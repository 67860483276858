import React, { useState } from "react";
import "../Styles/Services.css";
import offering_serives_img3 from "../Images/offering_serives_img3.png";
import offering_services_img1 from "../Images/offering_services_img1.png";
import offering_services_img2 from "../Images/offering_services_img2.png";
import ServicesServices from "../Components/ServicesServices";
import services_art_section from "../Images/services_art_section.png";
import digital_img from "../Images/digital_img.png";
import Softwarepicture from "../Images/Softwarepicture.png";
import portfolio_img1 from "../Images/portfolio_img1.png";
import portfolio_img2 from "../Images/portfolio_img2.png";
import portfolio_img3 from "../Images/portfolio_img3.png";
import Slider from "react-slick";
import { services_cards } from "../Config/Data";
import service_cards_img1 from "../Images/service_cards_img1.png";
import service_cards_img2 from "../Images/service_cards_img2.png";
import service_cards_img3 from "../Images/service_cards_img3.png";
import service_cards_img4 from "../Images/service_cards_img4.png";
import service_cards_img5 from "../Images/service_cards_img5.png";

import portfolio_section1_img from "../Images/portfolio_section1_img.png";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { A11y, Pagination, Scrollbar } from "swiper";


import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper";

const Services = () => {
  const [card1, setCard1] = useState(true);
  const [card2, setCard2] = useState(false);
  const [card3, setCard3] = useState(false);
  const [card4, setCard4] = useState(false);
  const [card5, setCard5] = useState(false);

  return (
    <>
      <section className="offering_hero_section">
        <div className="container ">
          <div className="offering_hero_left">
            <h1>Our</h1>
            <h2>Services</h2>
            <p>
              Since its inception in 2018, GreenWend Energy has been serving the
              people of this region with committment to provide best solar
              products at reasonable prices. Our rise to be the top solar energy
              company in the region is a reflection of how we take customer's
              satisfaction seriously. Over the short span of 4 years, we have a
              total installed capacity of more than 6 Mega Watts both in public
              and private sectors. "
            </p>
          </div>
        </div>
      </section>
      {/* react slider */}

      {/* our serives section */}
      <section className="services desktop_view_slider">
        <div className="container w-75 ">
          <div className="services_heading mt-4">
            <h2>Services</h2>
            <p>
              We are happy to use our expertise to work with you to develop a
              tailor-made solution for your challenge. Here are some highlights
              of our range of our offerings.
            </p>
          </div>
        </div>

        <div className="card_slider_main">
          <div className="slider_cards ">
            <Swiper
              slidesPerView={3}
              spaceBetween={45}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
              // breakpoints={{
              //   640: {
              //     slidesPerView: 1,
              //     spaceBetween: 20,
              //   },
              //   768: {
              //     slidesPerView: 2,
              //     spaceBetween: 40,
              //   },
              //   1024: {
              //     slidesPerView: 3,
              //     spaceBetween: 40,
              //   },
              // }}
              // pagination={{
              //   clickable: true,
              // }}
              // modules={[Pagination]}
              // className="mySwiper"
            >
              <SwiperSlide>
                {" "}
                <div
                  className={card1 ? " carded_first" : "carded "}
                  onClick={() => {
                    setCard1(true);
                    setCard2(false);
                    setCard3(false);
                    setCard4(false);
                    setCard5(false);
                  }}
                >
                  <figure>
                    <img src={offering_serives_img3} alt="img" />
                  </figure>
                  <h2> Energy Audit & Survey </h2>
                  <p>
                    We provide FREE energy audit for your homes and offices and
                    FREE site survey to determine the suitable solar solution
                    according to your site and requirements and here are the
                    ideas
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className={card2 ? " carded_second" : "carded "}
                  onClick={() => {
                    setCard1(false);
                    setCard2(true);
                    setCard3(false);
                    setCard4(false);
                    setCard5(false);
                  }}
                >
                  <figure>
                    <img src={service_cards_img2} alt="img" />
                  </figure>
                  <h2> Energy Audit & Survey </h2>
                  <p>
                    We provide FREE energy audit for your homes and offices and
                    FREE site survey to determine the suitable solar solution
                    according to your site and requirements and here are the
                    ideas
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className={card3 ? " carded_third" : "carded "}
                  onClick={() => {
                    setCard1(false);
                    setCard2(false);
                    setCard3(true);
                    setCard4(false);
                    setCard5(false);
                  }}
                >
                  <figure>
                    <img src={service_cards_img3} alt="img" />
                  </figure>
                  <h2> Energy Audit & Survey </h2>
                  <p>
                    We provide FREE energy audit for your homes and offices and
                    FREE site survey to determine the suitable solar solution
                    according to your site and requirements and here are the
                    ideas
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className={card4 ? " carded_four" : "carded "}
                  onClick={() => {
                    setCard1(false);
                    setCard2(false);
                    setCard3(false);
                    setCard4(true);
                    setCard5(false);
                  }}
                >
                  <figure>
                    <img src={service_cards_img4} alt="img" />
                  </figure>
                  <h2> Energy Audit & Survey </h2>
                  <p>
                    We provide FREE energy audit for your homes and offices and
                    FREE site survey to determine the suitable solar solution
                    according to your site and requirements and here are the
                    ideas
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className={card5 ? " carded_five" : "carded "}
                  onClick={() => {
                    setCard1(false);
                    setCard2(false);
                    setCard3(false);
                    setCard4(false);
                    setCard5(true);
                  }}
                >
                  <figure>
                    <img src={service_cards_img5} alt="img" />
                  </figure>
                  <h2> Energy Audit & Survey </h2>
                  <p>
                    We provide FREE energy audit for your homes and offices and
                    FREE site survey to determine the suitable solar solution
                    according to your site and requirements and here are the
                    ideas
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      {/* for mobile_view */}

      {/* service work section */}
      <section className="products mobile_view_slider">
        <div className="container ">
          <div className="products_heading">
            <h2>
              Our <span className="fw-bold products">Services</span>
            </h2>
            <p>
              We are happy to use our expertise to work with you to develop a
              tailor-made solution for your challenge. Here are some highlights
              of our range of our offerings.
            </p>
          </div>
        </div>

        <div className="container d-flex justify-content-center align-items-center">
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            centeredSlides={true}
            className="mySwiper"
            // pagination={{
            //   clickable: true,
            // }}
            // modules={[Pagination]}
            // className="mySwiper"
          >
            <SwiperSlide >
              {" "}
              <div className=" offering_mobile_slider">
                <div
                  className={card1 ? " carded_first" : "carded "}
                  onClick={() => {
                    setCard1(true);
                    setCard2(false);
                    setCard3(false);
                    setCard4(false);
                    setCard5(false);
                  }}
                >
                  <figure>
                    <img
                      src={offering_serives_img3}
                      alt="img"
                      className="images_products"
                    />
                  </figure>
                  <h2>Energy Audit & Survey</h2>
                  <p>
                    We provide FREE energy audit for your homes and offices and
                    FREE site survey to determine the suitable solar solution
                    according to your site and requirements and here are the
                    ideas
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className=" offering_mobile_slider">
                <div
                  className={card2 ? " carded_first" : "carded "}
                  onClick={() => {
                    setCard1(false);
                    setCard2(true);
                    setCard3(false);
                    setCard4(false);
                    setCard5(false);
                  }}
                >
                  <figure>
                    <img
                      src={service_cards_img2}
                      alt="img"
                      className="images_products"
                    />
                  </figure>
                  <h2>Energy Audit & Survey</h2>
                  <p>
                    We provide FREE energy audit for your homes and offices and
                    FREE site survey to determine the suitable solar solution
                    according to your site and requirements and here are the
                    ideas
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="offering_mobile_slider">
                <div
                  className={card3 ? " carded_first" : "carded "}
                  onClick={() => {
                    setCard1(false);
                    setCard2(false);
                    setCard3(true);
                    setCard4(false);
                    setCard5(false);
                  }}
                >
                  <figure>
                    <img
                      src={service_cards_img3}
                      alt="img"
                      className="images_products"
                    />
                  </figure>
                  <h2>Energy Audit & Survey</h2>
                  <p>
                    We provide FREE energy audit for your homes and offices and
                    FREE site survey to determine the suitable solar solution
                    according to your site and requirements and here are the
                    ideas
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="offering_mobile_slider">
                <div
                  className={card4 ? " carded_first" : "carded "}
                  onClick={() => {
                    setCard1(false);
                    setCard2(false);
                    setCard3(false);
                    setCard4(true);
                    setCard5(false);
                  }}
                >
                  <figure>
                    <img
                      src={service_cards_img4}
                      alt="img"
                      className="images_products"
                    />
                  </figure>
                  <h2>Energy Audit & Survey</h2>
                  <p>
                    We provide FREE energy audit for your homes and offices and
                    FREE site survey to determine the suitable solar solution
                    according to your site and requirements and here are the
                    ideas
                  </p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              {" "}
              <div className=" offering_mobile_slider">
                <div
                  className={card5 ? " carded_first" : "carded "}
                  onClick={() => {
                    setCard1(false);
                    setCard2(false);
                    setCard3(false);
                    setCard4(false);
                    setCard5(true);
                  }}
                >
                  <figure>
                    <img
                      src={service_cards_img5}
                      alt="img"
                      className="images_products"
                    />
                  </figure>
                  <h2>Energy Audit & Survey</h2>
                  <p>
                    We provide FREE energy audit for your homes and offices and
                    FREE site survey to determine the suitable solar solution
                    according to your site and requirements and here are the
                    ideas
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* <div className="container d-flex justify-content-center align-items-center">
          <div className="rows d-flex gap-5">
            <div className="col-4 gx-5 offering_mobile_slider">
              <div className="carde carde_first">
                <figure>
                  <img
                    src={offering_serives_img3}
                    alt="img"
                    className="images_products"
                  />
                </figure>
                <h2>Energy Audit & Survey</h2>
                <p>
                  We provide FREE energy audit for your homes and offices and
                  FREE site survey to determine the suitable solar solution
                  according to your site and requirements and here are the ideas
                </p>
              </div>
            </div>
            <div className="col-4 gx-5 offering_mobile_slider">
              <div className="carde carde_second">
                <figure>
                  <img
                    src={offering_serives_img3}
                    alt="img"
                    className="img-fluid"
                  />
                </figure>
                <h2 className="second_h2">Solar System Design</h2>
                <p>
                  It will only provide the power to the house and will also
                  charge your batteries for backup. Pre-Tailored packages
                  according to your need are present but you can also customize
                  them.
                </p>
              </div>
            </div>
            <div className="col-4 gx-5 offering_mobile_slider">
              <div className="carde carde_third">
                <figure>
                  <img
                    src={offering_serives_img3}
                    alt="img"
                    className="img-fluid images_products"
                  />
                </figure>
                <h2>Solar System Installation</h2>
                <p>
                  This system will provide the power for your house and also
                  charge batteries for backup as well as the extra energy will
                  be exported to the grid. Click here for more details and
                  offers.
                </p>
              </div>
            </div>{" "}
            <div className="col-4 gx-5 offering_mobile_slider">
              <div className="carde carde_third">
                <figure>
                  <img
                    src={offering_serives_img3}
                    alt="img"
                    className="img-fluid images_products"
                  />
                </figure>
                <h2>Solar Batteries</h2>
                <p>
                  This system will provide the power for your house and also
                  charge battries for backup as well as the extra energy will be
                  exported to the grid. Click here for more details and offers.
                </p>
              </div>
            </div>{" "}
            <div className="col-4 gx-5 offering_mobile_slider">
              <div className="carde carde_third">
                <figure>
                  <img
                    src={offering_serives_img3}
                    alt="img"
                    className="img-fluid images_products"
                  />
                </figure>
                <h2>Solar Batteries</h2>
                <p>
                  This system will provide the power for your house and also
                  charge battries for backup as well as the extra energy will be
                  exported to the grid. Click here for more details and offers.
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      {/* end for mobile view */}
      {/* end of services section */}

      {/*start sketching section */}
      {card1 && (
        <>
          <section className="service_sketching_section">
            <div className="container grid grid-two-column">
              <div className="service_sketch_image " data-aos="fade-down-right">
                <figure>
                  <img src={services_art_section} alt="img" className="" />
                </figure>
              </div>
              <div className="service_sketch_right" data-aos="fade-down-left">
                <h2>
                  {" "}
                  Sketching Your <br />
                  <span className="sketch_design">Design</span>{" "}
                </h2>
                <p>
                  Our highly expert and creative design team firstly make a
                  sketch the design for your solar design according to the data
                  collected by our survey team. This is the first step to live
                  your project this is just like the low fidelity wireframe of
                  your solar project and this is the first stage in the
                  designing phase.
                </p>
              </div>
            </div>
          </section>
          {/*end sketching section */}
          {/*start digital section */}
          <section className="service_digital_section">
            <div className="container grid grid-two-column">
              <div className="service_digital_right" data-aos="fade-down-right">
                <h2>
                  {" "}
                  Digitizing Your <br />{" "}
                  <span className="digital_design">Design</span>{" "}
                </h2>
                <p>
                  Then the professional digital media design team convert the
                  sketch of your project to the digital design to ease the flow
                  of work and to minimize the time. This is the second step for
                  your project this is just like the high fidelity wireframe of
                  your solar project and this is the second stage in the
                  designing phase.
                </p>
              </div>
              <div
                className="service_digital_image "
                data-aos="fade-down-right"
              >
                <figure>
                  <img src={digital_img} alt="img" className="" />
                </figure>
              </div>
            </div>
          </section>
          {/*end digital section */}
          {/*start software section */}
          <section className="service_software_section">
            <div className="container grid grid-two-column">
              <div
                className="service_software_image "
                data-aos="fade-down-right"
              >
                <figure>
                  <img src={Softwarepicture} alt="img" className="" />
                </figure>
              </div>
              <div className="service_software_right" data-aos="fade-down-left">
                <h2>
                  {" "}
                  <span className="software_design">Software</span> We <br />{" "}
                  Use
                </h2>
                <p>
                  Our design team use Figma as the design tool. Figma is a
                  digital product design tool that excels in six areas.
                  Accessibility and collaboration. Flexibility to iterate and
                  produce deliverables. Ability to build prototypes and test.
                  Establishing a single source of truth. Smooth handoff to
                  developers.
                </p>
              </div>
            </div>
          </section>
          {/*end software section */}
        </>
      )}

      {card2 && (
        <>
          {" "}
          {/*start digital section */}
          <section className="service_digital_section">
            <div className="container grid grid-two-column">
              <div className="service_digital_image" data-aos="fade-down-right">
                <figure>
                  <img src={digital_img} alt="img" className="" />
                </figure>
              </div>
              <div className="service_digital_right" data-aos="fade-down-left">
                <h2>
                  {" "}
                  Digitizing Your <br />{" "}
                  <span className="digital_design">Design</span>{" "}
                </h2>
                <p>
                  Then the professional digital media design team convert the
                  sketch of your project to the digital design to ease the flow
                  of work and to minimize the time. This is the second step for
                  your project this is just like the high fidelity wireframe of
                  your solar project and this is the second stage in the
                  designing phase.
                </p>
              </div>
            </div>
          </section>
          {/*end digital section */}
          <section className="service_sketching_section">
            <div className="container grid grid-two-column">
              <div className="service_sketch_right" data-aos="fade-down-right">
                <h2>
                  {" "}
                  Sketching Your <br />
                  <span className="sketch_design">Design</span>{" "}
                </h2>
                <p>
                  Our highly expert and creative design team firstly make a
                  sketch the design for your solar design according to the data
                  collected by our survey team. This is the first step to live
                  your project this is just like the low fidelity wireframe of
                  your solar project and this is the first stage in the
                  designing phase.
                </p>
              </div>{" "}
              <div className="service_sketch_image " data-aos="fade-down-left">
                <figure>
                  <img src={services_art_section} alt="img" className="" />
                </figure>
              </div>
            </div>
          </section>
          {/*end sketching section */}
          {/*start software section */}
          <section className="service_software_section">
            <div className="container grid grid-two-column">
              <div
                className="service_software_image "
                data-aos="fade-down-right"
              >
                <figure>
                  <img src={Softwarepicture} alt="img" className="" />
                </figure>
              </div>
              <div className="service_software_right" data-aos="fade-down-left">
                <h2>
                  {" "}
                  <span className="software_design">Software</span> We <br />{" "}
                  Use
                </h2>
                <p>
                  Our design team use Figma as the design tool. Figma is a
                  digital product design tool that excels in six areas.
                  Accessibility and collaboration. Flexibility to iterate and
                  produce deliverables. Ability to build prototypes and test.
                  Establishing a single source of truth. Smooth handoff to
                  developers.
                </p>
              </div>
            </div>
          </section>
          {/*end software section */}
        </>
      )}

      <div
        className="button_section"
        data-aos="fade-up"
        data-aos-duration="3000"
      >
        <div className="container grid grid-one-column">
          <button className="btn"> Get Services </button>
        </div>
      </div>

      {/* portfolio section */}

      <section>
        <div className="our_portfolio_section mb-7">
          <div className="container">
            <div className="portfolio_heading mt-10">
              <h1>Portfolio</h1>
              <p>Solar System Design </p>
            </div>
          </div>
          <div className="container ">
            <div className="row flex-column-reverse flex-lg-row mt-8 justify-content-center align-items-center">
              <div
                className="col-lg-6 col-md-6 col-12 portfolio_left_section"
                data-aos="fade-down-right"
              >
                <div className="portfolio_img ">
                  <img src={portfolio_img3} alt="img" className="img-fluid" />
                  <img
                    src={portfolio_img1}
                    alt="img"
                    className="img-fluid mt-0"
                  />
                </div>
                <div className="portfolio_img1 ml-2">
                  <img src={portfolio_img2} alt="img" className="img-fluid" />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-12 portfolio_right_main ">
                <div className="portfolio_right ml-6" data-aos="fade-down-left">
                  <h2>
                    Real-Life Example of{" "}
                    <span className="fw-bold">GreenWendEnergy</span> Project
                  </h2>
                  <p>
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                  <button>
                    {" "}
                    SEE MORE WORK <i className="fa fa-arrow-right "></i>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="photo_gallary mt-5 mb-5">
        <div className="container justify-content-start">
          <h1 className="photo_gallary_h1">Photo Gallary</h1>
        </div>
        <div className="container">
          <div className="mobile_portfolio_gallary_section">
            <img src={portfolio_section1_img} alt="img" />
            <img src={portfolio_section1_img} alt="img" />
            <img src={portfolio_section1_img} alt="img" />
          </div>
        </div>
      </div>

      {/* portfolio section */}
    </>
  );
};

export default Services;
