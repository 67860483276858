import React, { useState, useEffect } from "react";
import "../Styles/OfferingWork.css";
import work_img1 from "../Images/work_img1.png";
import work_img2 from "../Images/work_img2.png";
import work_img3 from "../Images/work_img3.png";
import work_connetion1 from "../Images/work_connetion1.png";
import work_connetion2 from "../Images/work_connetion2.png";
import { work_slider } from "../Config/Data";

const OfferingWork = () => {
  const [work, setWork] = useState(0);

  const value = work_slider[work];

  const ForWard = () => {
    const next = work + 1;
    const lent = work_slider.length;
    if (next === lent) {
      setWork(0);
    } else {
      setWork(next);
    }
  };

  const BackWard = () => {
    const back = work - 1;
    const lent = work_slider.length;
    
    if (back < 0) {
      setWork(lent - 1);
    } else {
      setWork(back);
    }
  };

  useEffect(() => {
    const setting = setTimeout(() => {
      ForWard();
    }, 2000);
    return () => clearTimeout(setting);
  }, [work]);

  return (
    <>
      <section className="serice_work_section mb-4">
        <div className="container">
          <div className="work_heading ">
            <h2>How we work for you?</h2>
            <p>
              We value our customers and try our level best to give them the
              best services. We also strive hard to provide good after sale
              services.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="mobile_view_offering">
            <div className="service_work1">
              <figure className="figure">
                <img src={value.img} alt="img" />
              </figure>
              <span className="dashed_circle"></span>
              <p> {value.desc} </p>
            </div>
          </div>
        </div>

        <div className="container desktop_view">
          <div className=" grid grid-three-column ">
            <div className="service_work1">
              <figure className="figure">
                <img src={work_img1} alt="img" />
              </figure>
              <span className="dashed_circle"></span>
              <p> Our professional team do survey of your location firstly </p>

              <img src={work_connetion2} alt="img" className="line_one" />
            </div>

            <div className="service_work1">
              <figure className="figure">
                <img src={work_img2} alt="img" />
              </figure>

              <span className="dashed_circle dashed-circle-active"></span>
              <p>
                Our skilled designers then design the most suitable solar system{" "}
              </p>
              <img src={work_connetion1} alt="img" className="line_two" />
            </div>
            <div className="service_work1">
              <figure className="figure">
                <img src={work_img3} alt="img" />
              </figure>

              <span className="dashed_circle"></span>
              <p>
                Then our highly professionals install the system at your place
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OfferingWork;
