import React, { useState } from "react";
import "../Styles/Portfolio.css";
import portfolio_section1_img from "../Images/portfolio_section1_img.png";
import portfolio_section2_img2 from "../Images/portfolio_section2_img2.png";
import portfolio_section2_img from "../Images/portfolio_section2_img.png";
import portfolio_overviw_img from "../Images/portfolio_overviw_img.png";
import portfolio_img1 from "../Images/portfolio_img1.png";
import portfolio_img2 from "../Images/portfolio_img2.png";
import portfolio_img3 from "../Images/portfolio_img3.png";
import portfolio1 from '../Images/portfolio1.jpg';
import portfolio2 from "../Images/portfolio2.jpg";
import portfolio3 from "../Images/portfolio3.jpg";
import portfolio4 from "../Images/portfolio4.jpg";
import portfolio5 from "../Images/portfolio5.jpg";
import portfolio6 from "../Images/portfolio6.jpg";
import portfolio7 from "../Images/portfolio7.jpg";
import portfolio8 from "../Images/portfolio8.jpg";
import portfolio9 from "../Images/portfolio9.png";

import { portfolio_cards } from "../Config/Data";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "swiper/css/navigation"; 
const Portfolio = () => {

  const { cards, setCards } = useState(portfolio_cards);
  return (
    <>
      <div className="portfolio_section1">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div
              className="col-lg-6 col-md-12 col-12 tablet_view"
              data-aos="fade-down-right"
            >
              <div className="portfolio_section1_left">
                <h1>GreenWendEnergy’s foot prints on the Green Planet.</h1>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 col-12 tablet_view"
              data-aos="fade-down-left"
            >
              <div className="portfolio_section1_left portfolio_section1_desktop">
                <figure>
                  <img
                    src={portfolio_section1_img}
                    alt="img"
                    className="img-fluid w-75"
                  />
                </figure>
              </div>
              <div
                className="portfolio_section1_mobile"
                data-aos="fade-down-left"
              >
                <img
                  src={portfolio_section1_img}
                  alt="img"
                  className="img-fluid"
                />
                <img
                  src={portfolio_section1_img}
                  alt="img"
                  className="img-fluid"
                />
                <img
                  src={portfolio_section1_img}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* portflio section 2 */}
      <div className="portfolio_achievenmetns">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 col-md-6 col-12 tablet_view"
              data-aos="fade-down-right"
            >
              <div className="portflio_achivenments_para">
                <p>
                  (SubTitle)range of services by GreenWendEnergy "Neque porro
                  quisquam est qui dolorem ipsum quia dolor sit amet,
                  consectetur, adipisci velit... quia dolor sit amet,
                  consectetur.
                </p>
              </div>
              <div className="portflio_achivenments_numbering">
                <h2>Achievements</h2>
                <div className="row">
                  <div className="col-lg-4 col-md-3 col-3">
                    <div className="numbers">
                      <h3>4243+</h3>
                      <p>Solar produced</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-3 col-3">
                    <div className="numbers">
                      <h3>4243+</h3>
                      <p>Solar produced</p>
                    </div>
                  </div>

                  <div className="col-lg-3  col-3 numbers_mobile_view">
                    <div className="numbers">
                      <h3>4243+</h3>
                      <p>Solar produced</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-3 numbers_mobile_view">
                    <div className="numbers">
                      <h3>4243+</h3>
                      <p>Solar produced</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4  col-3 numbers_desktop_view">
                    <div className="numbers">
                      <h3>4243+</h3>
                      <p>Solar produced</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-3 numbers_desktop_view">
                    <div className="numbers">
                      <h3>4243+</h3>
                      <p>Solar produced</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* right side */}
            <div
              className="col-lg-3 col-md-3 hide_in_mobile_view "
              data-aos="fade-down-left"
            >
              <figure className="figure1">
                <img src={portfolio_section2_img} alt="img" className="" />
              </figure>
            </div>
            <div
              className="col-lg-4 col-md-4 hide_in_mobile_view"
              data-aos="fade-down-left"
            >
              <figure className="figure2">
                <img src={portfolio_section2_img2} alt="img" className="" />
              </figure>
            </div>
            <hr className="mt-4" />
          </div>
        </div>
      </div>
      {/* portflio section 2 */}

      {/* portflio section 3 */}

      <div className="portfolio_overview">
        <div className="container">
          <div className="row mt-4 d-flex justify-content-center align-items-center">
            <div
              className="col-lg-6 col-12 gx-5 tablet_view"
              data-aos="fade-down-right"
            >
              <div className="porfolio_overview_img">
                <div className="mobile_overview_section">
                  <h3>Overview</h3>
                  <h2>
                    <span className="green">Green</span>{" "}
                    <span className="wend">Wend</span>{" "}
                    <span className="energy"> Energy</span>
                  </h2>
                </div>
                <figure>
                  <img
                    src={portfolio_overviw_img}
                    alt="img"
                    className="img-fluid "
                  />
                </figure>
              </div>
            </div>

            <div
              className="col-lg-6 col-md-12 col-12 gx-5 tablet_view"
              data-aos="fade-down-left"
            >
              <div className="porfolio_overview_para">
                <div className="portfolio_overview_desktop_heading">
                  <h3>Overview</h3>
                  <h2>
                    <span className="green">Green</span>{" "}
                    <span className="wend">Wend</span>{" "}
                    <span className="energy"> Energy</span>
                  </h2>
                </div>
                <p>
                  {" "}
                  GreenWendEnergy Solar System is the World's Smartest Solar
                  System with in-built Artificial Intelligence-based software,
                  Battery management software and 24/7 customer care services.
                  So far, GreenWendEnergy Pvt Ltd is the fastest-growing company
                  in Pakistan and has been successful in creating a footprint
                  all over Pakistan with clients from residential, commercial
                  and industrial segments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* portflio section 3 */}

      {/* portfolio section */}

      {/* portfolio section */}

      <section>
        <div className="our_portfolio_section mb-7">
          <div className="container">
            <div className="portfolio_heading mt-10">
              <h1>Portfolio</h1>
              <p>Solar System Design </p>
            </div>
          </div>
          <div className="container ">
            <div className="row flex-column-reverse flex-lg-row mt-8 d-flex justify-content-center align-items-center">
              <div
                className="col-lg-6 col-md-6 col-12 portfolio_left_section"
                data-aos="fade-down-right"
              >
                <div className="portfolio_img " style={{ width: "90%" }}>
                  <img src={portfolio7} alt="img" className="img-fluid " />
                  <img src={portfolio9} alt="img" className="img-fluid mt-2" />
                </div>
                <div className="portfolio_img1 mt-24 ml-2">
                  <img src={portfolio8} alt="img" className="img-fluid" />
                </div>
              </div>

              <div
                className="col-lg-6 col-md-6 col-12 portfolio_right_main "
                data-aos="fade-down-left"
              >
                <div className="portfolio_right ml-6">
                  <h2>
                    Hybrid System at{" "}
                    <span className="fw-bold">
                      {" "}
                      <br /> Giglit Baltistan
                    </span>
                  </h2>
                  <p>
                    Greenwend Energy is proud to announce the successful
                    installation of a hybrid solar system in the snowy mountains
                    of Gilgit. This challenging project required expert
                    engineering and a dedication to bringing sustainable energy
                    solutions to remote areas. The hybrid system, which combines
                    solar panels and a backup generator, is capable of providing
                    reliable power even in the face of harsh weather conditions
                    and limited sunlight. This installation is a testament to
                    our commitment to finding innovative ways to bring clean
                    energy to communities in need, no matter how difficult the
                    location may be. We are proud to have played a role in
                    bringing clean energy to this remote community and to have
                    demonstrated that renewable energy can be a viable option
                    even in the most challenging environments.
                  </p>
                  <button>
                    <NavLink to="/portfoliodetail" className="read_more">
                      READ MORE <i className="fa fa-arrow-right"></i>
                    </NavLink>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="photo_gallary">
        <div className="container justify-content-start">
          <h1 className="photo_gallary_h1">Photo Gallary</h1>
        </div>
        <div className="container">
          <div className="mobile_portfolio_gallary_section">
            <img src={portfolio1} alt="img" />
            <img src={portfolio2} alt="img" />
            <img src={portfolio3} alt="img" />
            <img src={portfolio4} alt="img" />
            <img src={portfolio5} alt="img" />
            <img src={portfolio6} alt="img" />
            <img src={portfolio7} alt="img" />
            <img src={portfolio8} alt="img" />
          </div>
        </div>
      </div>

      {/* portfolio section */}

      {/* portfolio section */}

      {/* portfolio section */}

      <section>
        <div className="our_portfolio_section mb-7">
          <div className="container ">
            <div className="row gx-5 flex-column-reverse flex-lg-row mt-20 mb-8 d-flex justify-content-center align-items-center">
              <div
                className="col-lg-6 col-md-6 col-12 portfolio_right_main "
                data-aos="fade-down-right"
              >
                <div className="portfolio_right ml-6">
                  <h2>
                    50 kW semi-hybrid system{" "}
                    <span className="fw-bold">opposite KFC,</span> Peshawar
                  </h2>
                  <p>
                    Introducing our biggest project yet: a 50 kW semi-hybrid
                    solar system installation opposite KFC in Peshawar. This
                    state-of-the-art system is designed to provide clean and
                    sustainable energy for the community, while also reducing
                    dependence on traditional fossil fuels. Our semi-hybrid
                    system is a combination of solar panels and a grid
                    connection, ensuring that the community always has access to
                    power. With a capacity of 50 kW and 94 panels, this system
                    is capable of powering a large number of homes and
                    businesses in the area. We are proud to have used a 60 kW
                    Huawei inverter for this project, which is known for its
                    efficiency and reliability. This, coupled with the use of 94
                    high-efficiency solar panels, ensures that this system will
                    provide maximum energy output for the community. The
                    community is thrilled to have access to clean and
                    sustainable energy, and we are proud to have played a part
                    in making that happen.
                  </p>
                  <button>
                    <NavLink to="/portfoliodetail" className="read_more">
                      READ MORE <i className="fa fa-arrow-right"></i>
                    </NavLink>
                  </button>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-12 portfolio_left_section"
                data-aos="fade-down-left"
              >
                <div
                  className="portfolio_img1 mt-20 ml-2"
                  style={{ width: "80%" }}
                >
                  <img src={portfolio6} alt="img" className="img-fluid" />
                </div>
                <div className="portfolio_img " style={{ width: "90%" }}>
                  <img src={portfolio4} alt="img" className="img-fluid ml-2" />
                  <img
                    src={portfolio5}
                    alt="img"
                    className="img-fluid ml-2 mt-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="photo_gallary">
        <div className="container d-flex justify-content-start">
          <h1 className="fw-bold photo_gallary_h1">Photo Gallary</h1>
        </div>
        <div className="container">
          <div className="mobile_portfolio_gallary_section">
            <img src={portfolio1} alt="img" />
            <img src={portfolio2} alt="img" />
            <img src={portfolio3} alt="img" />
            <img src={portfolio4} alt="img" />
            <img src={portfolio5} alt="img" />
            <img src={portfolio6} alt="img" />
            <img src={portfolio7} alt="img" />
            <img src={portfolio8} alt="img" />
          </div>
        </div>
      </div>

      {/* portfolio section */}

      <section>
        <div className="our_portfolio_section mb-7">
          <div className="container ">
            <div className="row gx-5 flex-column-reverse flex-lg-row mt-20 mb-8 d-flex justify-content-center align-items-center">
              <div
                className="col-lg-6 col-md-6 col-12 portfolio_left_section"
                data-aos="fade-down-left"
              >
                <div className="portfolio_img ">
                  <img
                    src={portfolio1}
                    alt="img"
                    className="img-fluid"
                    style={{ height: "60%" }}
                  />
                  <img src={portfolio2} alt="img" className="img-fluid mt-3" />
                </div>
                <div className="portfolio_img1 mt-20 ml-2">
                  <img
                    src={portfolio3}
                    alt="img"
                    className="img-fluid "
                    style={{ height: "100%" }}
                  />
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 col-12 portfolio_right_main "
                data-aos="fade-down-right"
              >
                <div className="portfolio_right ml-6">
                  <h2>
                    10kW System at{" "}
                    <span className="fw-bold">
                      {" "}
                      <br /> DHA Peshawar.
                    </span>
                  </h2>
                  <p>
                    GreenWend Energy installed a 10 kW Hybrid system at DHA
                    Peshawar for the former Chief of the State Bank of Pakistan.
                    We installed 18 panels of 545 watts and two 6Kw Fronius
                    Inverters. This is one of our earlier projects that took
                    place in 2021. This system is designed to provide clean and
                    sustainable energy for the community, while also reducing
                    dependence on traditional fossil fuels. Our hybrid system is
                    a combination of solar panels and a backup generator,
                    ensuring that the community always has access to power, even
                    during outages or periods of low sunlight. The 10 kW
                    capacity of the system is enough to power multiple homes and
                    businesses in the area. In addition to providing a reliable
                    source of energy, this project will also help to reduce
                    carbon emissions and improve air quality in the area. It's a
                    win-win for the community and the environment
                  </p>
                  <button>
                    <NavLink to="/portfoliodetail" className="read_more">
                      READ MORE <i className="fa fa-arrow-right"></i>
                    </NavLink>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="photo_gallary">
        <div className="container d-flex justify-content-start">
          <h1 className="fw-bold photo_gallary_h1">Photo Gallary</h1>
        </div>
        <div className="container">
          <div className="mobile_portfolio_gallary_section">
            <img src={portfolio1} alt="img" />
            <img src={portfolio2} alt="img" />
            <img src={portfolio3} alt="img" />
            <img src={portfolio4} alt="img" />
            <img src={portfolio5} alt="img" />
            <img src={portfolio6} alt="img" />
            <img src={portfolio7} alt="img" />
            <img src={portfolio8} alt="img" />
          </div>
        </div>
      </div>

      <div className="portfolio_slide_cards">
        <div className="container d-flex justify-content-start">
          <div className="heading_cards">
            <p>Our Potential Clients Saying</p>
          </div>
        </div>
        <div className="container">
          <div className="rows d-flex gap-5">
            {portfolio_cards.map((curElem, index) => {
              return (
                <>
                  <div
                    className="col-4 portfolio_mobile_cards"
                    key={curElem.id}
                  >
                    <div className="portfolio_cards">
                      <div className="potfolio_profile_img">
                        <figure>
                          <img src={curElem.profile} alt="img" />
                        </figure>
                        <div className="profile_data">
                          <h2> {curElem.name} </h2>
                          <p> {curElem.date} </p>
                        </div>
                      </div>
                      <div className="portfolio_slide_cards_para">
                        <p>{curElem.desc} </p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      <div className="portfolio_slide_cards_desktop">
        <div className="container d-flex justify-content-start">
          <div className="heading_cards">
            <p>Our Potential Clients Saying</p>
          </div>
        </div>
        <div className="container_div w-100 ">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            {portfolio_cards.map((curElem) => {
              return (
                <>
                  <SwiperSlide>
                    {" "}
                    <div className="portfolio_mobile_cards" key={curElem.id}>
                      <div className="portfolio_cards">
                        <div className="potfolio_profile_img">
                          <figure>
                            <img src={curElem.profile} alt="img" />
                          </figure>
                          <div className="profile_data">
                            <h2> {curElem.name} </h2>
                            <p> {curElem.date} </p>
                          </div>
                        </div>
                        <div className="portfolio_slide_cards_para">
                          <p>{curElem.desc} </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
        </div>{" "}
      </div>
    </>
  );
};

export default Portfolio;
