import React from "react";
import "../Styles/Projects.css";

const Projects = ({ ImgSrc, Title, Learn }) => {
  return (
    <>
      <div className="mt-4  project__div">
        <div className="project__image">
          <img src={ImgSrc} alt="img" />
        </div>
        <p className="projects__title">{Title}</p>
        <p className="projects__learn">
          {Learn} <i className="fa fa-arrow-right"></i>
        </p>
      </div>

      {/* for mobile view */}
    </>
  );
};

export default Projects;
