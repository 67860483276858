import React from "react";
import "../Styles/PortfolioDetail.css";
import blog_detail_img from "../Images/blog_detail_img.png";
import blog_detail_facebook from "../Images/blog_detail_facebook.png";
import blog_detail_twitter from "../Images/blog_detail_twitter.png";
import blog_detail_copy_img from "../Images/blog_detail_copy_img.png";
import blog_detail_linked from "../Images/blog_detail_linked.png";
import blog_detail_profile_img from "../Images/blog_detail_profile_img.png";
import hyperlink_img from "../Images/hyperlink_img.png";
import { Related } from "../Config/Data";
import { useState } from "react";
import portfolio1 from "../Images/portfolio1.jpg";
import portfolio2 from "../Images/portfolio2.jpg";
import portfolio3 from "../Images/portfolio3.jpg";
import portfolio4 from "../Images/portfolio4.jpg";
import portfolio5 from "../Images/portfolio5.jpg";
import portfolio6 from "../Images/portfolio6.jpg";
import portfolio7 from "../Images/portfolio7.jpg";
import portfolio8 from "../Images/portfolio8.jpg";
import aftab_marketing from "../Images/aftab_marketing.png";

import { image_slider } from "../Config/Data";
import Slider from "react-slick";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation, EffectFade } from "swiper";

const PortfolioDetail = () => {
  const [related, setRelated] = useState(image_slider);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    pauseOnHover: false,
  };

  return (
    <>
      <section className="blog_detail_page">
        <div className="container">
          <div className="blog_detail_heading">
            <h1>Work Category</h1>
            <h2>
              (Work Title)Range of Services By <br /> GreenWendEnergy
            </h2>
            <p>
              (Work SubTitle)range of services by GreenWendEnergy "Neque porro
              quisquam est qui dolorem ipsum quia dolor sit amet, consectetur,
              adipisci velit...
            </p>
          </div>
        </div>{" "}
        {/* profile_image */}
        <div className="container">
          <div className="blog_detail_profile_data">
            <div className="blog_profile_img">
              <figure>
                <img src={aftab_marketing} alt="img" />
              </figure>
              <div className="profile_data">
                <h2>Aftab Marketing</h2>
              </div>
            </div>
            <div className="blog_detail_profile_right">
              <div className="blog_links ">
                <a href="#">
                  <img
                    src={blog_detail_facebook}
                    alt="img"
                    className="facebook"
                  />
                </a>
              </div>

              <div className="blog_links">
                <a href="#">
                  <img src={blog_detail_twitter} alt="img" />
                </a>
              </div>

              <div className="blog_links">
                <a href="#">
                  <img src={blog_detail_linked} alt="img" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* end of profile image */}
        {/* big_image */}
        <div className="blog_detail_img portfolio_detail_desktop_view">
          {/* <div className="container"> */}
          {/* <figure>
              <Slider {...settings}>
                {related.map((value) => {
                  return (
                    <>
                      <div className="" key={value.id}>
                        <img
                          src={value.img}
                          alt="img"
                          className="img-fluid w-100 h-50"
                        />
                      </div>
                    </>
                  );
                })}
              </Slider>
            </figure> */}
          <div className="container-fluid">
            <Swiper
              navigation={true}
              effect={"fade"}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Navigation, EffectFade]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div>
                  <img src={portfolio1} alt="img" className="img-fluid " />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img src={portfolio2} alt="img" className="img-fluid " />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img src={portfolio3} alt="img" className="img-fluid " />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img src={portfolio4} alt="img" className="img-fluid " />
                </div>
              </SwiperSlide>{" "}
              <SwiperSlide>
                <div>
                  <img src={portfolio5} alt="img" className="img-fluid " />
                </div>
              </SwiperSlide>{" "}
              <SwiperSlide>
                <div>
                  <img src={portfolio6} alt="img" className="img-fluid " />
                </div>
              </SwiperSlide>{" "}
              <SwiperSlide>
                <div>
                  <img src={portfolio7} alt="img" className="img-fluid " />
                </div>
              </SwiperSlide>{" "}
              <SwiperSlide>
                <div>
                  <img src={portfolio8} alt="img" className="img-fluid " />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        {/* </div> */}
        {/* end big_image */}
      </section>

      {/* for mobile_view */}
      <div className="photo_gallary">
        <div className="container d-flex  justify-content-between ">
          <h1 className="photo_gallary_h1">Photo Gallary</h1>
          <p>11-March-2022</p>
        </div>
        <div className="container">
          <div className="mobile_portfolio_gallary_section">
            <img src={portfolio1} alt="img" />
            <img src={portfolio2} alt="img" />
            <img src={portfolio3} alt="img" />
            <img src={portfolio4} alt="img" />
            <img src={portfolio5} alt="img" />
            <img src={portfolio6} alt="img" />
            <img src={portfolio7} alt="img" />
            <img src={portfolio8} alt="img" />
          </div>
        </div>
      </div>
      {/* end for mobile view */}
      <section className="blog_details_hyperlinks">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="blog_details_left_hyperlinks">
                <div className="input_button">
                  <input
                    type="text"
                    placeholder="search"
                    className="input_img"
                  />
                </div>
                <div className="blog_details_content">
                  <p>Contents</p>
                  <ul>
                    <li>
                      <a href="#introduction">Introduction</a>
                    </li>
                    <li>
                      <a href="#why_greenwendenergy">Why GreenWendEnergy?</a>
                    </li>
                    <li>
                      <a href="#semi_hybrid">What is semi-Hybrid Energy?</a>
                    </li>
                    <li>
                      <a href="#">"How can secure your future."</a>
                    </li>
                    <li>
                      <a href="#">What is our goal?</a>
                    </li>
                    <li>
                      <a href="#">Follow up quetions?</a>
                    </li>
                    <li>
                      <a href="#">Conclusion</a>
                    </li>
                  </ul>
                </div>

                <div className="subscribe_div">
                  <h2>Subscribe to our news letter</h2>
                  <input type="text" placeholder="Enter Email" />
                  <div>
                    <button>Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="blog_details_right_hyperlink">
                <div className="section1_hyperlink">
                  <p id="why_greenwendenergy">
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                </div>
                <br />
                <div className="section2_hyperlink">
                  <p>
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                </div>
                <br />
                <div className="section3_hyperlink">
                  <p>
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                </div>{" "}
                <figure>
                  <img
                    src={hyperlink_img}
                    alt="img"
                    className="img-fluid w-100"
                  />
                </figure>
                <br />
                <div className="section4_hyperlink">
                  <p>
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                </div>
                <br />
                <div className="section5_hyperlink">
                  <p id="introduction">
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                </div>
                <br />{" "}
                <div className="section6_hyperlink">
                  <p>
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                </div>{" "}
                <br />
                <div className="section7_hyperlink">
                  <p id="semi_hybrid">
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </section>

      {/* end of hyper links section */}
    </>
  );
};

export default PortfolioDetail;
