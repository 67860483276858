import React from "react";
import { NavLink } from "react-router-dom";
import "../Styles/SolutionPage.css";
import SolutionPageSolution from "../Components/SolutionPageSolution";
import solution_img3 from "../Images/solution_img3.png";
import solution_img1 from "../Images/solution_img1.png";
import solution_img2 from "../Images/solution_img2.png";
import solution_sekeching_img from "../Images/solution_sekeching_img.png";
import solutionpage_fed_upimg from "../Images/solutionpage_fed_upimg.png";
import solutionpage_wisely_img from "../Images/solutionpage_wisely_img.png";
import package_cards_img1 from '../Images/package_cards_img1.png'
import package_cards_img2 from "../Images/package_cards_img2.png";
import package_cards_img3 from "../Images/package_cards_img3.png";
import package_cards_img4 from "../Images/package_cards_img4.png";
import package_details_img from "../Images/package_details_img.png";
import hybrid from "../Images/hybrid.png";
import portfolio_img1 from "../Images/portfolio_img1.png";
import portfolio_img2 from "../Images/portfolio_img2.png";
import portfolio_img3 from "../Images/portfolio_img3.png";
import portfolio_section1_img from "../Images/portfolio_section1_img.png";
import portfolio1 from "../Images/portfolio1.jpg";
import portfolio2 from "../Images/portfolio2.jpg";
import portfolio3 from "../Images/portfolio3.jpg";
import portfolio4 from "../Images/portfolio4.jpg";
import portfolio5 from "../Images/portfolio5.jpg";
import portfolio6 from "../Images/portfolio6.jpg";
import portfolio7 from "../Images/portfolio7.jpg";
import portfolio8 from "../Images/portfolio8.jpg";
import portfolio9 from "../Images/portfolio9.png";

import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { useState } from "react";


const SolutionPage = () => {

const [card1, setCard1] = useState(true)
const [card2, setCard2] = useState(false)
const [card3, setCard3] = useState(false)



  return (
    <>
      <section className="solution_hero_section">
        <div className="container">
          <div className="solution_hero_left">
            <h1>Our</h1>
            <h2>Solution</h2>
            <p>
              Since its inception in 2018, GreenWend Energy has been serving the
              people of this region with committment to provide best solar
              products at reasonable prices. Our rise to be the top solar energy
              company in the region is a reflection of how we take customer's
              satisfaction seriously. Over the short span of 4 years, we have a
              total installed capacity of more than 6 Mega Watts both in public
              and private sectors. "
            </p>
          </div>
        </div>
      </section>

      {/* our serives section */}
      <section className="solutionpage desktop_view_offering_cards">
        <div className="container w-75 ">
          <div className="solutionpage_heading">
            <h2>Services</h2>
            <p>
              We are happy to use our expertise to work with you to develop a
              tailor-made solution for your challenge. Here are some highlights
              of our range of our offerings.
            </p>
          </div>
        </div>

        <div className="container d-flex justify-content-center align-items-center">
          <div className="rows d-flex gap-5">
            <SolutionPageSolution
              solution_img3={solution_img3}
              solution_img2={solution_img2}
              hybrid={hybrid}
              setCard1={setCard1}
              card1={card1}
              card2={card2}
              card3={card3}
              setCard2={setCard2}
              setCard3={setCard3}
            />
          </div>
        </div>
      </section>

      {/* end of services section */}
      <section className="solutionpage mobile_view_offering_cards">
        <div className="container w-75 ">
          <div className="solutionpage_heading">
            <h2>Services</h2>
            <p>
              We are happy to use our expertise to work with you to develop a
              tailor-made solution for your challenge. Here are some highlights
              of our range of our offerings.
            </p>
          </div>
        </div>{" "}
        <div className="container">
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            centeredSlides={true}
            // pagination={{
            //   clickable: true,
            // }}
            // modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              {" "}
              <div className=" mobile_slider">
                <div
                  className={
                    card1 ? "carded_solution_first" : "carded_solution"
                  }
                  onClick={() => {
                    setCard1(true);
                    setCard2(false);
                    setCard3(false);
                  }}
                >
                  <figure>
                    <img src={solution_img2} alt="img" />
                  </figure>
                  <h2>On- Grid System</h2>
                  <p>
                    We provide FREE energy audit for your homes and offices and
                    FREE site survey to determine the suitable solar solution
                    according to your site and requirements
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="mobile_slider " href="#semi_hybrid">
                <div
                  className={
                    card2 ? "carded_solution_second" : "carded_solution"
                  }
                  onClick={() => {
                    setCard1(false);
                    setCard2(true);
                    setCard3(false);
                  }}
                >
                  <figure>
                    <img src={hybrid} alt="img" className="img-fluid" />
                  </figure>
                  <h2 className="second_h2">Semi-Hybrid System</h2>
                  <p>
                    It will only provide the power to the house and will also
                    charge your batteries for backup. Pre-Tailored packeges
                    accourding to your need are present but you can also
                    customize them.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="mobile_slider">
                <div
                  className={
                    card3 ? "carded_solution_third" : "carded_solution"
                  }
                  onClick={() => {
                    setCard1(false);
                    setCard2(false);
                    setCard3(true);
                  }}
                >
                  <figure>
                    <img src={solution_img3} alt="img" className="img-fluid " />
                  </figure>
                  <h2>Hybrid System</h2>
                  <p>
                    This system will provide the power for your house and also
                    charge battries for backup as well as the extra energy will
                    be exported to the grid. Click here for more details and
                    offers.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      {/*start sketching section */}
      {card1 && (
        <>
          <section className="solutionpage_sketching_section">
            <div className="container grid grid-two-column">
              <div
                className="solutionpage_sketch_image "
                data-aos="fade-down-right"
              >
                <figure>
                  <img src={solution_sekeching_img} alt="img" />
                </figure>
              </div>
              <div
                className="solutionpage_sketch_right"
                data-aos="fade-down-left"
              >
                <h2>
                  {" "}
                  What is <span className="sketch_design">
                    {" "}
                    Semi- Hybrid
                  </span>{" "}
                  System
                </h2>
                <p>
                  To understand the concept of Semi Hybrid Solar Energy System
                  first we must understand the concepts of{" "}
                  <a href="#" className="sketch_links">
                    on-grid solar system
                  </a>{" "}
                  and{" "}
                  <a href="#" className="sketch_links">
                    hybrid solar system
                  </a>
                  . On-Grid solar systems are connected to your electricity
                  supply from grid. An On-Grid system exports all the
                  electricity to the grid. The user consume electricity from
                  grid.
                </p>
              </div>
            </div>
          </section>
          {/*end sketching section */}
          {/*start fed-up section */}
          <section className="solutionpage_fed_up_section" id="grid_system">
            <div className="container grid grid-two-column">
              <div
                className="solutionpage_fed_up_right"
                data-aos="fade-down-right"
              >
                <h2>
                  {" "}
                  Fed-up of Power{" "}
                  <span className="sketch_design"> Blackout</span> System
                </h2>
                <p>
                  Then the professional digital media design team convert the
                  sketch of your project to the digital design to ease the flow
                  of work and to minimize the time. This is the second step for
                  your project this is just like the high fidelity wireframe of
                  your solar project and this is the second stage in the
                  designing phase.
                </p>
              </div>
              <div
                className="solutionpage_fed_up_image "
                data-aos="fade-down-left"
              >
                <figure>
                  <img src={solutionpage_fed_upimg} alt="img" />
                </figure>
              </div>
            </div>
          </section>
          {/*end fed-up section */}
          {/*start wisely section */}
          <section className="solutionpage_wisely_section">
            <div className="container grid grid-two-column">
              <div
                className="solutionpage_wisely_image "
                data-aos="fade-down-right"
              >
                <figure>
                  <img src={solutionpage_wisely_img} alt="img" />
                </figure>
              </div>
              <div
                className="solutionpage_wisely_right"
                data-aos="fade-down-left"
              >
                <h2>
                  {" "}
                  Choose Wisely Choose{" "}
                  <span className="sketch_design"> Greenwend</span>
                </h2>
                <p>
                  Our design team use Figma as the design tool. Figma is a
                  digital product design tool that excels in six areas.
                  Accessibility and collaboration. Flexibility to iterate and
                  produce deliverables. Ability to build prototypes and test.
                  Establishing a single source of truth. Smooth handoff to
                  developers.
                </p>
              </div>
            </div>
          </section>{" "}
        </>
      )}

      {card2 && (
        <>
          <section className="solutionpage_wisely_section">
            <div className="container grid grid-two-column">
              <div
                className="solutionpage_wisely_right"
                data-aos="fade-down-right"
              >
                <h2>
                  {" "}
                  Choose Wisely Choose{" "}
                  <span className="sketch_design"> Greenwend</span>
                </h2>
                <p>
                  Our design team use Figma as the design tool. Figma is a
                  digital product design tool that excels in six areas.
                  Accessibility and collaboration. Flexibility to iterate and
                  produce deliverables. Ability to build prototypes and test.
                  Establishing a single source of truth. Smooth handoff to
                  developers.
                </p>
              </div>{" "}
              <div
                className="solutionpage_wisely_image "
                data-aos="fade-down-left"
              >
                <figure>
                  <img src={solutionpage_wisely_img} alt="img" />
                </figure>
              </div>
            </div>
          </section>{" "}
          <section className="solutionpage_sketching_section">
            <div className="container grid grid-two-column">
              <div
                className="solutionpage_sketch_image "
                data-aos="fade-down-right"
              >
                <figure>
                  <img src={solution_sekeching_img} alt="img" />
                </figure>
              </div>
              <div
                className="solutionpage_sketch_right"
                data-aos="fade-down-left"
              >
                <h2>
                  {" "}
                  What is <span className="sketch_design">
                    {" "}
                    Semi- Hybrid
                  </span>{" "}
                  System
                </h2>
                <p>
                  To understand the concept of Semi Hybrid Solar Energy System
                  first we must understand the concepts of{" "}
                  <a href="#" className="sketch_links">
                    on-grid solar system
                  </a>{" "}
                  and{" "}
                  <a href="#" className="sketch_links">
                    hybrid solar system
                  </a>
                  . On-Grid solar systems are connected to your electricity
                  supply from grid. An On-Grid system exports all the
                  electricity to the grid. The user consume electricity from
                  grid.
                </p>
              </div>
            </div>
          </section>
          {/*end sketching section */}
          {/*start fed-up section */}
          <section className="solutionpage_fed_up_section" id="grid_system">
            <div className="container grid grid-two-column">
              <div
                className="solutionpage_fed_up_right"
                data-aos="fade-down-right"
              >
                <h2>
                  {" "}
                  Fed-up of Power{" "}
                  <span className="sketch_design"> Blackout</span> System
                </h2>
                <p>
                  Then the professional digital media design team convert the
                  sketch of your project to the digital design to ease the flow
                  of work and to minimize the time. This is the second step for
                  your project this is just like the high fidelity wireframe of
                  your solar project and this is the second stage in the
                  designing phase.
                </p>
              </div>
              <div
                className="solutionpage_fed_up_image "
                data-aos="fade-down-left"
              >
                <figure>
                  <img src={solutionpage_fed_upimg} alt="img" />
                </figure>
              </div>
            </div>
          </section>
          {/*end fed-up section */}
          {/*start wisely section */}
        </>
      )}
      {/*end wisely section */}

      <div className="solution_page_packages_cards">
        <div className="container">
          <div className="packages_cards_heading">
            <h2>
              Pre-customized{" "}
              <span className="packages_cards_blue">Packages</span>
            </h2>
            <p>
              GreenWendEnergy had customized packages which suits a large
              variety of users they are made after a deep study of customers
              needs. But you can also customize the offer which suits you.
            </p>
          </div>
        </div>

        <div className="packages_cards_div">
          <div className="container">
            <div className="grid grid-four-column package_slider">
              <div className="package_cards_data">
                <img src={package_cards_img4} alt="img" />
                <h2>10-KW</h2>
                <p className="packages_cards_para">Basic Package</p>
                <p className="packages_pkr">1,000,000pkr</p>
                <a href="#" className="card_link">
                  GET PACKAGE <i className="fa fa-arrow-right"></i>{" "}
                </a>
              </div>
              <div className="package_cards_data packages_cards_first">
                <img src={package_cards_img3} alt="img" />
                <h2>15-KW</h2>
                <p className="packages_cards_para">Standard Package</p>
                <p className="packages_pkr">1,500,000pkr</p>
                <a href="#" className="card_link">
                  GET PACKAGE <i className="fa fa-arrow-right"></i>{" "}
                </a>
              </div>
              <div className="package_cards_data packages_cards_second">
                <img src={package_cards_img2} alt="img" />

                <h2>20-KW</h2>
                <p className="packages_cards_para">Premium Package</p>
                <p className="packages_pkr">2,000, 000pkr</p>
                <a href="#" className="card_link">
                  GET PACKAGE <i className="fa fa-arrow-right"></i>{" "}
                </a>
              </div>
              <div className="package_cards_data packages_cards_third">
                <img src={package_cards_img1} alt="" />
                <h2>Customize Packages </h2>
                <a href="#" className="card_link">
                  Customize PACKAGE <i className="fa fa-arrow-right"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* solution packages section end*/}

      {/* package details */}
      <section className="solutionpage_package_datails_section ">
        <div className="container grid grid-two-column">
          <div
            className="solutionpage_package_datails_image "
            data-aos="fade-down-right"
          >
            <figure>
              <img src={package_details_img} alt="img" />
            </figure>
          </div>
          <div
            className="solutionpage_package_datails_right"
            data-aos="fade-down-left"
          >
            <h2>Packages Details</h2>
            <p>10 kW system is adequate to run an entire house.</p>
            <div className="package_details_list">
              <p className="inludes">This package includes:</p>

              <li>Installation. </li>
              <li>Three 1.5 Ton Inverter Air Conditioner.</li>
              <li>Water Pump/Motor</li>
              <li>Refrigerator</li>
              <li>Few lights and Fans</li>
            </div>
          </div>
        </div>
      </section>

      {/* package details */}

      {/*end software section */}

      <div
        className="button_section"
        data-aos="fade-up"
        data-aos-duration="3000"
      >
        <div className="container grid grid-one-column">
          <button className="btn"> Get Services </button>
        </div>
      </div>

      {/* portfolio section */}

      <section>
        <div className="our_portfolio_section mb-7">
          <div className="container">
            <div className="portfolio_heading mt-10">
              <h1>Portfolio</h1>
              <p>Solar System Design </p>
            </div>
          </div>
          <div className="container ">
            <div className="row flex-column-reverse flex-lg-row mt-8 justify-content-center align-items-center">
              <div
                className="col-lg-6 col-md-6 col-12 portfolio_left_section"
                data-aos="fade-down-right"
              >
                <div className="portfolio_img ">
                  <img src={portfolio1} alt="img" className="img-fluid" />
                  <img src={portfolio2} alt="img" className="img-fluid" />
                </div>
                <div className="portfolio_img1 ml-2">
                  <img src={portfolio3} alt="img" className="img-fluid" />
                </div>
              </div>

              <div
                className="col-lg-6 col-md-6 col-12 portfolio_right_main "
                data-aos="fade-down-right"
              >
                <div className="portfolio_right ml-6">
                  <h2>
                    10kW System at{" "}
                    <span className="fw-bold">
                      {" "}
                      <br /> DHA Peshawar.
                    </span>
                  </h2>
                  <p>
                    GreenWend Energy installed a 10 kW Hybrid system at DHA
                    Peshawar for the former Chief of the State Bank of Pakistan.
                    We installed 18 panels of 545 watts and two 6Kw Fronius
                    Inverters. This is one of our earlier projects that took
                    place in 2021. This system is designed to provide clean and
                    sustainable energy for the community, while also reducing
                    dependence on traditional fossil fuels. Our hybrid system is
                    a combination of solar panels and a backup generator,
                    ensuring that the community always has access to power, even
                    during outages or periods of low sunlight. The 10 kW
                    capacity of the system is enough to power multiple homes and
                    businesses in the area. In addition to providing a reliable
                    source of energy, this project will also help to reduce
                    carbon emissions and improve air quality in the area. It's a
                    win-win for the community and the environment
                  </p>
                  <button>
                    <NavLink to="/portfoliodetail" className="read_more">
                      READ MORE <i className="fa fa-arrow-right"></i>
                    </NavLink>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="photo_gallary mt-5 mb-5">
        <div className="container justify-content-start">
          <h1 className="photo_gallary_h1">Photo Gallary</h1>
        </div>
        <div className="container">
          <div className="mobile_portfolio_gallary_section">
            <img src={portfolio1} alt="img" />
            <img src={portfolio2} alt="img" />
            <img src={portfolio3} alt="img" />
            <img src={portfolio4} alt="img" />
            <img src={portfolio5} alt="img" />
            <img src={portfolio6} alt="img" />
            <img src={portfolio7} alt="img" />
            <img src={portfolio8} alt="img" />
          </div>
        </div>
      </div>
      {/* <section>
        <div className="our_portfolio_section mb-7">
          <div className="container">
            <div className="portfolio_heading mt-10">
              <h1>Portfolio</h1>
              <p>Solar System Design </p>
            </div>
          </div>
          <div className="container ">
            <div className="row mt-8">
              <div className="col-lg-3">
                <div className="portfolio_img">
                  <img src={portfolio_img3} alt="img" className="img-fluid" />
                  <img
                    src={portfolio_img1}
                    alt="img"
                    className="img-fluid mt-2"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 ">
                <div className="portfolio_img1 mt-20">
                  <img src={portfolio_img2} alt="img" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="portfolio_right ml-6">
                  <h2>
                    Real-Life Example of{" "}
                    <span className="fw-bold">GreenWendEnergy</span> Project
                  </h2>
                  <p>
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                  <button>
                    {" "}
                    SEE MORE WORK <i className="fa fa-arrow-right "></i>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* portfolio section */}
    </>
  );
};

export default SolutionPage;
