import React from "react";
import "../Styles/About.css";
import people from "../Images/People.png";
import enjoy from "../Images/enjoy.png";
import Footer from "../Components/Footer";
import hand from "../Images/hand.png";
import ceo from "../Images/ceo.png";
import ceo2 from "../Images/ceo2.png";
import { team } from "../Config/Data";
import Team from "../Components/Team";
import LongiLogo from "../Images/LongiLogo.png";
import HuaweiLogo from "../Images/HuaweiLogo.png";
import Solislogo from "../Images/Solislogo.png";
import Pylonlogo from "../Images/Pylonlogo.png";
import Canadiansolarlogo from "../Images/Canadiansolarlogo.svg";
import { useState } from "react";
import original_ceo from "../Images/original_ceo.png";

const About = () => {
  const [toggle, setToggle] = useState(true);

  return (
    <>
      <div className="about_main_div">
        <div className="container grid grid-two-column">
          <div className="about_left_main" data-aos="fade-down-right">
            <div className="about_left">
              <p className="about__head">Economy | Efficency | Effectiveness</p>
              <h2 className="about__title">
                About Our Company{" "}
                <span className="wend text-[#5EA410]">Green</span>
                <span className="wend text-[#202E86]">Wend</span>
                <span className="wend text-[#F6951D]">Energy</span>
              </h2>
            </div>
            <div className="about__desc">
              <p>
                Greenwend Energy Pvt Ltd is here to help you in your difficult
                situations like power cut, High electricity bills and much more.
              </p>
            </div>
          </div>

          <div className="image__div" data-aos="fade-down-left">
            <img src={people} alt="img" />
          </div>
        </div>
      </div>
      <br />
      <br />
      {/* enjoy section start */}
      <div
        className="enjoy_section"
        data-aos="fade-up"
        data-aos-duration="3000"
      >
        <div className="container">
          <div className="enjoy__image">
            <img src={enjoy} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
      {/* enjoy section end */}
      <div className="about_cards">
        <div className="about_cards_another grid grid-four-column">
          <div className="about__maindiv">
            <p className="about__details">100%</p>
            <p className="about__accuracy">Accuracy</p>
            <p className="about__team">We are having a highly expert team</p>
          </div>

          <div className="about__maindiv">
            <p className="about__details">150+</p>
            <p className="about__accuracy">Projects Done</p>
            <p className="about__team">
              We have installed 150+ systems all over country
            </p>
          </div>

          <div className="about__maindiv">
            <p className="about__details">250+</p>
            <p className="about__accuracy">Happy Clients</p>
            <p className="about__team">
              All our clients are highly satisfied from our work
            </p>
          </div>

          <div className="about__maindiv">
            <p className="about__details">20+</p>
            <p className="about__accuracy">Products</p>
            <p className="about__team">
              We are having latest solar products for you{" "}
            </p>
          </div>
        </div>
      </div>
      <br />

      <div className="hand__image">
        <h2 className="hand__title">
          Our <span className="font-bold">Trusted partners</span>
        </h2>
        <div className="container">
          <div className="logos">
            <img
              src={Canadiansolarlogo}
              alt="img"
              className="img-fluid candaian_img"
            />
            <img src={LongiLogo} alt="img" className="img-fluid" />
            <img src={HuaweiLogo} alt="img" className="img-fluid" />
            <img src={Solislogo} alt="img" className="img-fluid" />
            <img src={Pylonlogo} alt="img" className="img-fluid" />
          </div>
        </div>
      </div>

      <br />
      <div className="ceo_mision_section ">
        <div className="container ">
          <div className="ceo_down_section grid grid-two-column">
            <div className="ceo__about" data-aos="fade-down-right">
              <h2 className="ceo__mission">Our Mission</h2>

              <h2 className="ceo__leading">
                Leading{" "}
                <span
                  className="
          text-[#5462BA]"
                >
                  Energy
                </span>{" "}
                Provider
              </h2>

              <p className="ceo__message">
                Our mission is to provide the highest quality cost effective
                renewable energy power products and services and enhance our
                customer's satisfaction through improvement in energy
                utilization, reducing energy bills and environment protection.
              </p>
            </div>
            <div className="ceo__mainImage" data-aos="fade-down-left">
              <img src={original_ceo} className="ceo__image" alt="img" />
              <div className="ceo__name">
                <p className="ceo__mr">Mr. Zeeshan Syed</p>
                <p className="ceo__company">CEO- GreenWendEnergy</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      {/* Our Vision  */}
      <div className="ceo_vision_section">
        <div className="container grid grid-two-column">
          <div className="ceo__visionImage" data-aos="fade-down-right">
            <img src={ceo2} className="img_fluid" alt="" />
          </div>

          <div className="ceo__vision" data-aos="fade-down-left">
            <h2 className="ceo__visionMessage">Our Vision</h2>

            <h2 className="ceo__wants">
              Company Wants to{" "}
              <span
                className="
          text-[#5462BA] achive"
              >
                Achieve
              </span>
            </h2>

            <p className="ceo__visionPara">
              We strive to become one of the leading and most reliable solar
              energy company providing the best services and innovative
              products. Continuously conforming to our ever-growing professional
              demeanor as well as quality assurance standards to earn the
              privilege of being 1st choice.
            </p>
          </div>
        </div>
      </div>
      <br />
      {/* Values Area */}
      <div className="value mt-8">
        <p className="value__head">
          Our <span className="fw-bold">Values</span>
        </p>

        <h2 className="value__range">PRODUCE HIGH RANGE OF POWER</h2>

        <p className="value__contain">
          {" "}
          We value our customers and try our level best to give them the best
          services. We also strive hard to provide good after sale services.
        </p>
      </div>
      <br />
      <br />
      <br />
      {/* Team */}
      <div className="team">
        <div className="team_down_section">
          <p className="team__para">GreenWendEnergy Team</p>

          <h2 className="team__amazing">The Amazing Team Of Us</h2>

          {/* {Team is Card, This 'team' is Data Pass Through Cards} */}
          <div className="flex flex-wrap justify-center gap-24 container mt-3 mobile_view_team_section">
            {team.map((it) => {
              return (
                <>
                  <Team
                    ImgSrc={it.img}
                    Name={it.title}
                    position={it.position}
                  />
                </>
              );
            })}
          </div>
        </div>
      </div>
      {/* ********************* board bazar section ******************** */}
      <div className="board_bazar_section mt-5">
        <div className="container w-100 d-grid align-items-start justify-content-start">
          {" "}
          <div className="visit_left_office" data-aos="zoom-in-down">
            <p className="visit_office">Our Offices</p>
            <h2 className="visit_heading">Now We're In 2 Cities </h2>
            <h3 className="visit_us">Visit Us</h3>
            <p className="visit_para">
              We are here to bring light to your homes and make your life easy
              in this tough situation.
            </p>
          </div>
        </div>

        <div className="container grid grid-two-column mt-4 mb-5">
          <div className="left_side" data-aos="fade-down-right">
            <div className="left_board">
              <div className="toggle_btns">
                <h2 className="board_heading">
                  Board Bazar, Peshawar, Pakistan{" "}
                </h2>{" "}
                <button onClick={() => setToggle(!toggle)}>
                  {toggle ? (
                    <i className=" fa-solid fa-3x fa-square-minus "></i>
                  ) : (
                    <i className=" fa-solid fa-3x fa-square-plus"></i>
                  )}
                </button>
              </div>
              {toggle ? (
                <div>
                  <p className="board_para">
                    GreenWendEnergy Pvt Ltd, office no.10, 3rd floor, Khyber
                    Heights building, near Afghan Sadaqat juice shop, University
                    Rd, Board Bazar, Peshawar, Pakitan
                    <div>
                      <button className="btn"> CONTACT US </button>
                    </div>
                  </p>
                </div>
              ) : null}
            </div>
            <div className="blue_area d-flex flex-column">
              <div className="another_div_blue_area ">
                <h2>Blue Area, Islamabad, Pakistan </h2>{" "}
                <button onClick={() => setToggle(!toggle)}>
                  {toggle ? (
                    <i className=" fa-solid fa-3x fa-square-plus"></i>
                  ) : (
                    <i className=" fa-solid fa-3x fa-square-minus "></i>
                  )}
                </button>{" "}
              </div>
              {!toggle ? (
                <div>
                  <p className="board_para">
                    Islamabad Office: Office 3, Ground Floor, Safdar Mansion
                    near Tabaq Hotel, Blue Area Islamabad.
                    <div>
                      <button className="btn"> CONTACT US </button>
                    </div>
                  </p>
                </div>
              ) : null}
            </div>{" "}
          </div>
          {toggle ? (
            <div className="right_board" data-aos="fade-down-left">
              <div className="board_image">
                <div className="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3307.788191609062!2d71.46552491455387!3d33.997973828036855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38d9171adad932f7%3A0x8cd12167470027fd!2sGreenWend%20Energy%20Pvt%20Ltd!5e0!3m2!1sen!2s!4v1673977797795!5m2!1sen!2s"
                    className="map_hieght_width"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="right_board" data-aos="fade-down-left">
                <div className="board_image">
                  <div className="map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.5363800354157!2d73.07699401454568!3d33.72094364256472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbffdb851775f%3A0xb0d5188e772d66b1!2sGreenWend%20Energy%20Pvt%20Ltd!5e0!3m2!1sen!2s!4v1674036972935!5m2!1sen!2s"
                      className="map_hieght_width"
                      style={{ border: 0 }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
              ;
            </>
          )}
        </div>
      </div>

      {/* for mobile view */}

      <div className="board_bazar_section_mobile_view">
        <div className="container">
          <div className="visit_left_office" data-aos="fade-down-right">
            <p className="visit_office">Our Offices</p>
            <h2 className="visit_heading">Now We're In 2 Cities </h2>
            <h3 className="visit_us">Visit Us</h3>
            <p className="visit_para">
              We are here to bring light to your homes and make your life easy
              in this tough situation.
            </p>
          </div>
        </div>
        <div className="container grid grid-two-column">
          <div className="left_side" data-aos="fade-down-left">
            <div className="left_board" data-aos="fade-down-left">
              {" "}
              <div className="toggle_btns" onClick={() => setToggle(!toggle)}>
                <h2 className="board_heading">
                  Board Bazar, Peshawar, Pakistan{" "}
                </h2>{" "}
                <button onClick={() => setToggle(!toggle)}>
                  {toggle ? (
                    <i className=" fa-solid fa-3x fa-square-minus upper_minus"></i>
                  ) : (
                    <i className=" fa-solid fa-3x fa-square-plus upper_plus"></i>
                  )}
                </button>
              </div>
              {toggle ? (
                <div>
                  <p className="board_para">
                    GreenWendEnergy Pvt Ltd, office no.10, 3rd floor, Khyber
                    Heights building, near Afghan Sadaqat juice shop, University
                    Rd, Board Bazar, Peshawar, Pakitan
                    <div>
                      <button className="btn fw-bold"> CONTACT US </button>
                    </div>
                  </p>
                  <div
                    className="right_board_mobile_view"
                    data-aos="fade-down-right"
                  >
                    <div className="board_image">
                      <div className="map">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3307.788191609062!2d71.46552491455387!3d33.997973828036855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38d9171adad932f7%3A0x8cd12167470027fd!2sGreenWend%20Energy%20Pvt%20Ltd!5e0!3m2!1sen!2s!4v1673977797795!5m2!1sen!2s"
                          className="map_hieght_width"
                          style={{ border: 0 }}
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="blue_area mb-5 " data-aos="fade-down-right">
              <div
                className="mobile_blue_area_div"
                onClick={() => setToggle(!toggle)}
              >
                <div className="another_div_blue_area">
                  <h2>Blue Area, Islamabad, Pakistan </h2>
                  <button onClick={() => setToggle(!toggle)}>
                    {toggle ? (
                      <i className=" fa-solid fa-3x fa-square-plus upper_minus"></i>
                    ) : (
                      <i className=" fa-solid fa-3x fa-square-minus upper_plus"></i>
                    )}
                  </button>{" "}
                </div>

                {!toggle ? (
                  <div>
                    <p className="board_para">
                      Islamabad Office: Office 3, Ground Floor, Safdar Mansion
                      near Tabaq Hotel, Blue Area Islamabad.
                      <div>
                        <button className="btn fw-bold"> CONTACT US </button>
                      </div>
                    </p>
                    <div
                      className="right_board_mobile_view"
                      data-aos="fade-down-left"
                    >
                      <div className="board_image">
                        <div className="map">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.5363800354157!2d73.07699401454568!3d33.72094364256472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbffdb851775f%3A0xb0d5188e772d66b1!2sGreenWend%20Energy%20Pvt%20Ltd!5e0!3m2!1sen!2s!4v1674036972935!5m2!1sen!2s"
                            className="map_hieght_width"
                            style={{ border: 0 }}
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* for fmobile view end */}
    </>
  );
};

export default About;
