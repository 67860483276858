import React, { useState } from "react";
import { FaTimes, FaBars, FaLowVision } from "react-icons/fa";
import "../Styles/Navbar.css";
import { NavLink } from "react-router-dom";
import search from "../Images/search.png";
import Logo from "../Images/Logo.png";

const Navbar = () => {
  const [icon, setIcon] = useState(false);

  const [toggle, setToggle] = useState(false);

  return (
    <div className="navbar">
      <div className="logo_img">
        <img src={Logo} className="img-fluid" alt="img" />
      </div>
      {/*List*/}
      <div className="">
        <ul className={icon ? "menu active" : "menu"}>
          <li>
            <NavLink
              onClick={() => setIcon(!icon)}
              className={({ isActive }) => (isActive ? "navStyle" : null)}
              to="/"
            >
              HOME
            </NavLink>
          </li>

          <li>
            <NavLink
              onClick={() => setIcon(!icon)}
              className={({ isActive }) => (isActive ? "navStyle" : null)}
              to="/about"
            >
              ABOUT
            </NavLink>
          </li>

          <li
            onMouseEnter={() => {setToggle( !toggle && (
              <ul className="drop_down_menu" onClick={() => setIcon(!icon)}>
                <li>
                  <NavLink
                    onClick={() => setIcon(!icon)}
                    className={({ isActive }) => (isActive ? "navStyle" : null)}
                    to="/services"
                  >
                    SERVICES <i class="fa fa-angle-right"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => setIcon(!icon)}
                    className={({ isActive }) => (isActive ? "navStyle" : null)}
                    to="/solution"
                  >
                    SOLUTION <i class="fa fa-angle-right"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => setIcon(!icon)}
                    className={({ isActive }) => (isActive ? "navStyle" : null)}
                    to="/products"
                  >
                    PRODUCTS <i class="fa fa-angle-right"></i>
                  </NavLink>
                </li>
              </ul>
            )
          )}}
            onMouseLeave={() => setToggle(null)}
            onClick={() => {
              setToggle(!toggle);
            }}
          >
            <NavLink
              onClick={() => setIcon(!icon)}
              className={({ isActive }) => (isActive ? "navStyle" : null)}
              to="/offering"
            >
              OFFERING{" "}
            </NavLink>
            {/* <KeyboardArrowDownIcon /> */}
            <span>
              {toggle ? (
                <i className="fa fa-angle-up down_arrow"></i>
              ) : (
                <i className="fa fa-angle-down down_arrow"></i>
              )}
            </span>

            {toggle && (
              <ul className="drop_down_menu" onClick={() => setIcon(!icon)}>
                <li>
                  <NavLink
                    onClick={() => setIcon(!icon)}
                    className={({ isActive }) => (isActive ? "navStyle" : null)}
                    to="/services"
                  >
                    SERVICES <i class="fa fa-angle-right"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => setIcon(!icon)}
                    className={({ isActive }) => (isActive ? "navStyle" : null)}
                    to="/solution"
                  >
                    SOLUTION <i class="fa fa-angle-right"></i>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => setIcon(!icon)}
                    className={({ isActive }) => (isActive ? "navStyle" : null)}
                    to="/products"
                  >
                    PRODUCTS <i class="fa fa-angle-right"></i>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li>
            <NavLink
              onClick={() => setIcon(!icon)}
              className={({ isActive }) => (isActive ? "navStyle" : null)}
              to="/blog"
            >
              BLOG
            </NavLink>
          </li>

          <li>
            <NavLink
              onClick={() => setIcon(!icon)}
              className={({ isActive }) => (isActive ? "navStyle" : null)}
              to="/portfolio"
            >
              PORTFOLIO
            </NavLink>
          </li>

          <NavLink className="search">
            <figure>
              <img src={search} alt="img" />
            </figure>
          </NavLink>
        </ul>
        <div className="menu__icon">
          <span className="navbar__icon" onClick={() => setIcon(!icon)}>
            {!icon ? <FaBars /> : <FaTimes />}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
