import React from "react";
import Navbar from "./Components/Navbar";
import Main from "./Pages/Main";
import Offering from "./Pages/Offering";
import About from "./Pages/About";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Services from "./Pages/Services";
import SolutionPage from "./Pages/SolutionPage";
import Footer from "./Components/Footer";
import ProductsPage from "./Pages/ProductsPage";
import BlogsPage from "./Pages/BlogsPage";
import Blogs_detail_page from "./Pages/Blogs_detail_page";
import Portfolio from "./Pages/Portfolio";
import PortfolioDetail from "./Pages/PortfolioDetail";
import ScrollToTop from "./Components/ScrollToTop";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewFooter from "./Components/NewFooter";

const Index = () => {
  return (
    <div>
      {/* <Footer /> */}

      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/offering" element={<Offering />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/solution" element={<SolutionPage />} />
          <Route exact path="/products" element={<ProductsPage />} />
          <Route exact path="/blog" element={<BlogsPage />} />
          <Route exact path="/blogdetail" element={<Blogs_detail_page />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/portfoliodetail" element={<PortfolioDetail />} />
        </Routes>
        <Footer />
        {/*  */}
      </BrowserRouter>
    </div>
  );
};

export default Index;
