import React from 'react'
import alpha_series from "../Images/alpha_series.jpeg";
import nova_crown from "../Images/nova_crown.png";
import fronus_4kw from "../Images/fronus_4kw.jpg";
import growatt_hd from "../Images/growatt_hd.png";
import veryan from "../Images/veryan.jpg";
import Jaguar_Series from "../Images/Jaguar_Series.jpeg";
import nitrox from "../Images/nitrox.jpg";
import tesla from "../Images/tesla.png";
import goodwe1 from "../Images/goodwe1.jpg";
import goodwe2 from "../Images/goodwe2.png";
import goodwe3 from "../Images/goodwe3.jpg";
import growatt_ongrid from "../Images/growatt_ongrid.jpg";
import HUAWEI from "../Images/HUAWEI.jpg";
import nitrox_ongrid from "../Images/nitrox_ongrid.jpeg";
import soliss from "../Images/soliss.jpg";
import sungrow from "../Images/sungrow.png";

const Inverter = () => {
  return (
    <>
      <div className="hybrid_solar_panels">
        <div className="container flex-column gap-3 mt-24">
          <h2>Hybrid Solar Panels</h2>
          <h3> Alpha Series (Hybrid inverter)</h3>
          <p>
            GreenWend energy’s very own Alpha Series are the most powerful
            inverters which supports 8000w/9880w/13000w/15600w PV built-in Dual
            Mppt internal SPD Pakistan's First Solar Inverter with Dual PV
            Input.
          </p>
        </div>
      </div>
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={alpha_series} alt="img" className="" />
            </figure>
          </div>
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>SPECS</h3>
                <ul>
                  <li>Maxi Efficiency 98.7% </li>
                  <li>IP 65 </li>
                  <li>7 years warranty</li>
                  <li>24/7 Live app monitoring</li>
                  <li>support upto 75000 pv input</li>
                  <li>Battery Type:Lead-acid or Li-ion</li>
                  <li>Battery Voltage Range:40-60V</li>
                  <li>Installation Type: Wall Mounted</li>
                  <li>Max Charging Current: 125-240A</li>
                  <li>Max input power: 7500-15600W</li>
                  <li>Startup Voltage: 125-150V</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>
      {/* alpha end */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3> FRONUS SPECS </h3>
                <ul>
                  <li>Multiple MPPT</li>
                  <li>High efficiency up to 98.8%.</li>
                  <li>
                    Remote monitoring and control via a smartphone or computer.
                  </li>
                  <li>Compact and lightweight.</li>
                  <li>
                    Built-in safety features: over-temperature protection,
                    over-voltage protection, and short-circuit protection to
                    ensure safe operation.
                  </li>
                  <li>Compatible with all kind of batteries</li>
                  <li>24/7 Live app monitoring</li>
                  <li>2 years Replacement warranty</li>
                  <li>Selectable high power charging current</li>
                  <li>5 years repair warranty</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={fronus_4kw} alt="img" className="img-fluid" />
            </figure>
          </div>
        </div>
      </section>
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={nova_crown} alt="img" className="img-fluid" />
            </figure>
          </div>
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>Crown Nova</h3>
                <ul>
                  <li>Dual MPPT charge controller in 7.8KW</li>
                  <li>Models available: 3.6-7.8 KW</li>
                  <li>Customizable status LED bar with RGB lights</li>
                  <li>
                    Built-in WiFi for mobile monitoring (Android/iOS App is
                    available)
                  </li>
                  <li>Supports USB On-the-Go function</li>
                  <li>Replaceable fan design for easy maintenance</li>
                  <li>Battery independent (without battery) design</li>
                  <li>
                    Configurable AC/PV output usage timer and prioritization
                  </li>
                  <li>Selectable high power charging current</li>
                  <li>
                    Selectable input voltage range for home appliances and
                    personal computers
                  </li>
                  <li>Compatible to Utility Mains or generator input</li>
                  <li>Built-in-Anti-dust kit</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>GroWatt Inverters</h3>
                <ul>
                  <li>High efficiency: 98%.</li>
                  <li>Inverters available in 5kW,10kW, 20kW.</li>
                  <li>MPPT (Maximum Power Point Tracking) technology:</li>
                  <li>Wide input voltage range</li>
                  <li>Compact and lightweight design</li>
                  <li>
                    Built-in safety features: over-temperature protection,
                    over-voltage protection, and short-circuit protection.
                  </li>
                  <li>
                    Built-in communication options such as WiFi, GPRS, RS485,
                    USB, and Ethernet.
                  </li>
                  <li>Remote monitoring and control.</li>
                  <li>Warranty: 5 years.</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={growatt_hd} alt="img" className="img-fluid" />
            </figure>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>Tesla</h3>
                <ul>
                  <li>Power: 3.8kW</li>
                  <li>Maximum power point trackers: 2</li>
                  <li>Weight: 52 lbs</li>
                  <li>Efficiency: 97.5% at 208 V or 97.5% at 240 V</li>
                  <li>Installation: Indoor or outdoor</li>
                  <li>Warranty: 12.5 years</li>
                  <li>Inverter (MPPT Tracking)</li>
                  <li>Compatible with all kind of batteries</li>
                  <li>24/7 Live app monitoring</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={tesla} alt="img" className="img-fluid" />
            </figure>
          </div>
        </div>
      </section>
      {/*  */}
      <div className="hybrid_solar_panels">
        <div className="container flex-column gap-3 mt-24">
          <h2>Jaguar Series (Hybrid inverter)</h2>
          <p>
            GreenWend presents Jaguar Series' most powerful inverters which
            supports 6000w/8000w PV, having built in Dual Mppt, Also its
            Pakistan's 1st Solar Inverter with Dual PV Input 13Ax2, with an
            elegant RGB ring light, touch button and LCD.
          </p>
        </div>
      </div>
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={Jaguar_Series} alt="img" className="img-fluid" />
            </figure>
          </div>
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>6KTL and 8KTL SPECS</h3>
                <ul>
                  <li>Dual AC and 12Vdc output</li>
                  <li>Installation Type: Wall Mounted</li>
                  <li>Max Charging Current: 120A</li>
                  <li>Max PV input power:6000-8000W</li>
                  <li>Startup Voltage: 120V</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      {/*  */}
      <div className="hybrid_solar_panels">
        <div className="container flex-column gap-3 mt-24">
          <h2>Inverex</h2>
        </div>
      </div>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={veryan} alt="img" className="img-fluid" />
            </figure>
          </div>
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>Inverex Veyron </h3>
                <ul>
                  <li>Available in 1.5, 2.5, 5.2KW</li>
                  <li>2 Batteries</li>
                  <li>Auto Synchronization with Inverex power wall.</li>
                  <li>Smart Capacitive touch LCD of 4.3 Inches.</li>
                  <li>Output Power factor 1.0</li>
                  <li>Pure sine Wave MPPT Solar Inverter.</li>
                  <li>Priority Setting for Grid/PV usage.</li>
                  <li>Compatible to main voltage or generator power.</li>
                  <li>Auto Restart While AC Is Recovering.</li>
                  <li>Conformal Coating For Internal Boarding</li>
                  <li>Built-in-Anti-Dust kit</li>
                  <li>Upgraded MPPT Solar Charger</li>
                  <li>Configurable RGB LED To Indicate Inverter Status</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>{" "}
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>NITROX INVERTER</h3>
                <ul>
                  <li>Max Efficiency 97.6%</li>
                  <li>Ip65 Ingress Protection</li>
                  <li>Dual Mppt</li>
                  <li>WHigh Surge Endurance</li>
                  <li>Max charging current 120A</li>
                  <li>High Power Factor</li>
                  <li>Touch Screen Display</li>
                  <li>5 Years Warranty</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={nitrox} alt="img" className="img-fluid" />
            </figure>
          </div>
        </div>
      </section>
      {/* ongrid solar pannels */}
      <div className="hybrid_ongrid_section">
        <div className="container">
          <h2>On-grid Solar Panels</h2>
        </div>
      </div>
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          {" "}
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={goodwe1} alt="img" className="img-fluid" />
            </figure>
          </div>
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>The GoodWe Smart DT series inverter</h3>
                <ul>
                  <li>three-phase home solar systems</li>
                  <li>
                    covering a wide power range of 5kW, 6kW,10kW, 15kW, and
                    20kW.
                  </li>
                  <li>
                    The integrated two MPPTs allow two-array inputs from
                    different roof orientations.
                  </li>
                  <li>5 kW Grid-tied Power</li>
                  <li>Battery Type: Lead-acid or Li-Ion</li>
                  <li>Single phase</li>
                  <li>MPPT: 2</li>
                  <li>Suitable for both outdoor and indoor .</li>
                  <li>natural convection cooling.</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          {" "}
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>The GoodWe Smart DT series inverter </h3>
                <ul>
                  <li>three-phase home solar systems</li>
                  <li>
                    covering a wide power range of 5kW, 6kW,10kW, 15kW, and
                    20kW.
                  </li>
                  <li>
                    The integrated two MPPTs allow two-array inputs from
                    different roof orientations.
                  </li>
                  <li>5 kW Grid-tied Power</li>
                  <li>Battery Type: Lead-acid or Li-Ion</li>
                  <li>Single phase</li>
                  <li>MPPT: 2</li>
                  <li>Suitable for both outdoor and indoor .</li>
                  <li>natural convection cooling.</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={goodwe2} alt="img" className="img-fluid" />
            </figure>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          {" "}
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={goodwe3} alt="img" className="img-fluid" />
            </figure>
          </div>
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>GoodWe 15KW </h3>
                <ul>
                  <li>Max. DC input power: 19500 W</li>
                  <li>MPPT Input voltage range: 200-850 V</li>
                  <li>Rated output voltage: 400V, 3L/N/PE</li>
                  <li>Display/Communication: LCD and LED/RS485 or WiFi</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          {" "}
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>GroWatt </h3>
                <ul>
                  <li>Available in Maximum Efficiency of 98.9%</li>
                  <li>Multi MPP Controller MTL String</li>
                  <li>8 strings intelligent monitoring and Fuse protection</li>
                  <li>Internal DC switch and Transformer-less</li>
                  <li>Type II surge arresters for both DC and AC</li>
                  <li>Anti-PID for PV module</li>
                  <li>Optional AC Switch / AFCI Function</li>
                  <li>Supporting AC Power Supply</li>
                  <li>Compact Design and Easy Installation</li>
                  <li>Comprehensive Warranty Program</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={growatt_ongrid} alt="img" className="img-fluid" />
            </figure>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          {" "}
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={HUAWEI} alt="img" className="img-fluid" />
            </figure>
          </div>{" "}
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>Huawei </h3>
                <ul>
                  <li>Available in 10,15,20,25,100KW </li>
                  <li>Anti-Islanding protection</li>
                  <li>DC reverse polarity protection</li>
                  <li>Insulation monitoring</li>
                  <li>DC lightening protection</li>
                  <li>AC lightening protection</li>
                  <li>Residual current monitoring</li>
                  <li>AC short-circuit protection</li>
                  <li>AC over-voltage protection</li>
                  <li>Ripple receiver control</li>
                  <li>Arc fault protection (AFCI)</li>
                  <li>Integrated PID recovery</li>
                  <li>ON-Grid Inverter</li>
                  <li>Maxi Efficiency 98.7%</li>
                  <li>Dual MPPT</li>
                  <li>10 years warranty</li>
                  <li>24/7 live app monitoring</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          {" "}
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>Nitrox </h3>
                <ul>
                  <li>Dual Maximum Power Point Tracking Controller </li>
                  <li>Three Phase Operating</li>
                  <li>High Surge Endurance</li>
                  <li>Zero Export</li>
                  <li>High Power Factor</li>
                  <li>AC Connection IP65</li>
                  <li>IP65 Ingress Protection</li>
                  <li>Maxi Efficiency 98.7%</li>
                  <li>10 years warranty</li>
                  <li>24/7 live app monitoring</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={nitrox_ongrid} alt="img" className="img-fluid" />
            </figure>
          </div>{" "}
        </div>
      </section>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          {" "}
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={soliss} alt="img" className="img-fluid" />
            </figure>
          </div>{" "}
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>Solis ON-Grid Inverter</h3>
                <ul>
                  <li>Available in 10, 15 20kW </li>
                  <li>Efficiency 97.0%</li>
                  <li>Wide voltage range and low start-up voltage</li>
                  <li>7.0″ LCD color screen display</li>
                  <li>Compact and light design, easy installation</li>
                  <li>IP65 rated for outdoor installation</li>
                  <li>Anti-resonance, supporting over 6MW paralleled</li>
                  <li>in one transformer</li>
                  <li>RS485, WiFi/LAN/GPRS (optional) interface</li>
                  <li>
                    WiFi monitoring available-Android and Apple App store
                    available
                  </li>
                  <li>Maxi Efficiency 98.7%</li>
                  <li>Dual MPPT</li>
                  <li>5 years warranty</li>
                  <li>24/7 live app monitoring</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="productpage_longi_section">
        <div className="container grid grid-two-column">
          {" "}
          <div className="productpage_longi_right" data-aos="fade-down-right">
            <div className="longi_micro_crack mt-4">
              <div>
                <h3>SunGrow Inverters</h3>
                <ul>
                  <li>Available in 10, 15, 20 kW </li>
                  <li>ON-Grid Inverter</li>
                  <li>Maxi Efficiency 98.7%</li>
                  <li>Dual MPPT</li>
                  <li>10 years warranty</li>
                  <li>24/7 live app monitoring</li>
                  <li>Dimensions (W*H*D) : 370*485*160 mm</li>
                  <li>Weight: 20 kg</li>
                  <li>Nominal input voltage: 600V</li>
                </ul>
              </div>
            </div>

            <div className="longi_btn">
              <button>Get Product</button>
            </div>
          </div>
          <div className="productpage_longi_image " data-aos="fade-down-left">
            <figure>
              <img src={sungrow} alt="img" className="img-fluid" />
            </figure>
          </div>{" "}
        </div>
      </section>
    </>
  );
}

export default Inverter