import React from "react";
import "../Styles/Cards.css";
import setting from "../Images/setting.png";
import light from "../Images/light.png";
import box from "../Images/box.png";
import { Navigate, NavLink, useNavigate } from "react-router-dom";



const Cards = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="cards" data-aos="fade-down-right">
        <div className=" w-100 d-flex justify-content-center align-items-center  flex-column ">
          <img src={setting} className="cards__image" alt="img" />
          <p className="cards__title ">Services</p>
          <p className="cards__desc">
            We provide FREE energy audit for your homes and offices and FREE
            site survey to determine the suitable solar solution according to
            your site and requirements.
          </p>
          <p
            className="cards__learn"
            onClick={() => navigate("/services")}
            style={{ cursor: "pointer" }}
          >
            <button>
              {" "}
              Learn more <i className="fa fa-arrow-right"></i>
            </button>
          </p>
        </div>
      </div>
      <div
        className="cards"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <div className=" w-100 d-flex justify-content-center align-items-center  flex-column ">
          <img src={light} className="light_img" alt="img" />
          <p className="cards__title ">Solution</p>
          <p className="cards__desc compress">
            We design customized solutions keeping in mind your unique energy
            requirements and affordability. Our vast customer base across KP and
            ICT region is a testament for our tailor made solutions.
          </p>
          <p
            className="cards__learn"
            onClick={() => navigate("/solution")}
            style={{ cursor: "pointer" }}
          >
            <button>
              {" "}
              Learn more <i className="fa fa-arrow-right"></i>
            </button>
          </p>
        </div>
      </div>{" "}
      <div className="cards" data-aos="fade-down-left">
        <div className=" w-100 d-flex justify-content-center align-items-center  flex-column ">
          <img src={box} className="cards__image" alt="img" />
          <p className="cards__title ">Products</p>
          <p className="cards__desc">
            GreenWend uses top of the line equipment and products from the
            world's top brands to ensure efficient and smooth operation of your
            solar energy system.
          </p>
          <p
            className="cards__learn"
            onClick={() => navigate("/products")}
            style={{ cursor: "pointer" }}
          >
            <button>
              {" "}
              Learn more <i className="fa fa-arrow-right"></i>
            </button>
          </p>
        </div>
      </div>
    </>
  );
};

export default Cards;
