import React from "react";
import "../Styles/Blog_detail.css";
import blog_detail_img from "../Images/blog_detail_img.png";
import blog_detail_facebook from "../Images/blog_detail_facebook.png";
import blog_detail_twitter from "../Images/blog_detail_twitter.png";
import blog_detail_copy_img from "../Images/blog_detail_copy_img.png";
import blog_detail_linked from "../Images/blog_detail_linked.png";
import blog_detail_profile_img from "../Images/blog_detail_profile_img.png";
import hyperlink_img from "../Images/hyperlink_img.png";
import { Related } from "../Config/Data";
import { useState } from "react";


import related_img1 from "../Images/related_img1.png";
import related_img2 from "../Images/related_img2.png";
import related_img3 from "../Images/related_img3.png";
import aftab_marketing from "../Images/aftab_marketing.png";


const Blogs_detail_page = () => {
  const [related, setRelated] = useState(Related);

  return (
    <>
      <section className="blog_detail_page">
        <div className="container">
          <div className="blog_detail_heading">
            <h1>Blog Category</h1>
            <h2>
              (Blog Title)range of services by <br /> GreenWendEnergy
            </h2>
            <p>
              (Blog SubTitle)range of services by GreenWendEnergy "Neque porro
              quisquam est qui dolorem ipsum quia dolor sit amet, consectetur,
              adipisci velit...
            </p>
          </div>
        </div>{" "}
        {/* big_image */}
        <div className="blog_detail_img">
          <div className="container">
            <figure>
              <img src={blog_detail_img} alt="img" />
            </figure>
          </div>
        </div>
        {/* end big_image */}
        {/* profile_image */}
        <div className="container">
          <div className="blog_detail_profile_data">
            <div className="blog_profile_img">
              <figure>
                <img src={aftab_marketing} alt="img" />
              </figure>
              <div className="profile_data">
                <h2>Aftab Marketing</h2>
                <p>11-March-2022</p>
              </div>
            </div>
            <div className="blog_detail_profile_right">
              <div className=" blog_copy_link">
                <a
                  href="#"
                  className="d-flex justify-content-center align-items-center"
                >
                  <img src={blog_detail_copy_img} alt="img" className="link" />
                  &nbsp; <span className="copy_link">Copy link</span>
                  <a rel="stylesheet" href="" />
                </a>
              </div>

              <div className="blog_links ">
                <a href="#">
                  <img
                    src={blog_detail_facebook}
                    alt="img"
                    className="facebook"
                  />
                </a>
              </div>

              <div className="blog_links">
                <a href="#">
                  <img src={blog_detail_twitter} alt="img" />
                </a>
              </div>

              <div className="blog_links">
                <a href="#">
                  <img src={blog_detail_linked} alt="img" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* end of profile image */}
      </section>

      <section className="blog_details_hyperlinks">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 mobile_none">
              <div className="blog_details_left_hyperlinks">
                <div className="input_button">
                  <input
                    type="text"
                    placeholder="search"
                    className="input_img"
                  />
                </div>
                <div className="blog_details_content">
                  <p>Contents</p>
                  <ul>
                    <li>
                      <a href="#">Introduction</a>
                    </li>
                    <li>
                      <a href="#">Why GreenWendEnergy?</a>
                    </li>
                    <li>
                      <a href="#">What is semi-Hybrid Energy?</a>
                    </li>
                    <li>
                      <a href="#">"How can secure your future."</a>
                    </li>
                    <li>
                      <a href="#">What is our goal?</a>
                    </li>
                    <li>
                      <a href="#">Follow up quetions?</a>
                    </li>
                    <li>
                      <a href="#">Conclusion</a>
                    </li>
                  </ul>
                </div>

                <div className="subscribe_div">
                  <h2>Subscribe to our news letter</h2>
                  <input type="text" placeholder="Enter Email" />
                  <div>
                    <button>Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="blog_details_right_hyperlink">
                <div className="section1_hyperlink">
                  <p>
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                </div>
                <br />
                <div className="section2_hyperlink">
                  <p>
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                </div>
                <br />
                <div className="section3_hyperlink">
                  <p>
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                </div>
                <figure>
                  <img
                    src={hyperlink_img}
                    alt="img"
                    className="img-fluid w-100"
                  />
                </figure>
                <br />
                <div className="section4_hyperlink">
                  <p>
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                </div>
                <br />
                <div className="section5_hyperlink">
                  <p>
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                </div>
                <br />{" "}
                <div className="section6_hyperlink">
                  <p>
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                </div>{" "}
                <br />
                <div className="section7_hyperlink">
                  <p>
                    GWE (Pvt.) Ltd. is a privately held energy company that
                    provide a large variety of services which ranges from your
                    energy audit surveys to your personalized solar system
                    designing by the creative team we have and then followed by
                    the solar system installation by one of the most skillful
                    and highly expert team under the supervision of skilled
                    engineers. GWE (Pvt.) Ltd also provide their customers
                    remote monitoring through NOC, the best after sale services
                    in the town and also net metering.
                  </p>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </section>

      {/* end of hyper links section */}

      {/* related content section */}
      <section className="blog_detail_related d-none">
        <div className="container ">
          <div className="related_heading ml-3">
            <h2>Related Content</h2>
            <p>
              GWE (Pvt) Ltd. is a privately held energy company that provide a
              large variety metering.
            </p>
          </div>
          <div className="veiw_all">
            <p>
              VIEW ALL <i class="fa fa-arrow-right"></i>{" "}
            </p>{" "}
          </div>
        </div>
        <div className="blog_related_img_section">
          <div className="container ">
            <div className="rows ">
              <div className="col-4 blog_detail_cards_mobile gx-5 margin_left_portfolio_cards">
                <div className="blog_detail_img_cards">
                  <figure>
                    <img src={related_img1} alt="img" />
                  </figure>
                  <h3 className="mt-2">Blog Category</h3>
                  <h2 className="blog_detail_cards_heading">
                    Lorem Ipsum is simply dummy text of the printing.
                  </h2>
                  <p>
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an has.
                  </p>
                  <div className="blog_profile_related d-flex mt-2">
                    <figure>
                      <img
                        src={blog_detail_profile_img}
                        alt="img"
                        className="w-50"
                      />
                    </figure>
                    <div className="profile_data">
                      <h2> Bloger Name</h2>
                      <p> 11-March-2022 08 min read</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-4 blog_detail_cards_mobile gx-5">
                <div className="blog_detail_img_cards">
                  <figure>
                    <img src={related_img1} alt="img" />
                  </figure>
                  <h3 className="mt-2">Blog Category</h3>
                  <h2 className="blog_detail_cards_heading">
                    Lorem Ipsum is simply dummy text of the printing.
                  </h2>
                  <p>
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an has.
                  </p>
                  <div className="blog_profile_related d-flex mt-2">
                    <figure>
                      <img
                        src={blog_detail_profile_img}
                        alt="img"
                        className="w-50"
                      />
                    </figure>
                    <div className="profile_data">
                      <h2> Bloger Name</h2>
                      <p> 11-March-2022 08 min read</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 blog_detail_cards_mobile gx-5">
                <div className="blog_detail_img_cards">
                  <figure>
                    <img src={related_img1} alt="img" />
                  </figure>
                  <h3 className="mt-2">Blog Category</h3>
                  <h2 className="blog_detail_cards_heading">
                    Lorem Ipsum is simply dummy text of the printing.
                  </h2>
                  <p>
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an has.
                  </p>
                  <div className="blog_profile_related d-flex mt-2">
                    <figure>
                      <img
                        src={blog_detail_profile_img}
                        alt="img"
                        className="w-50"
                      />
                    </figure>
                    <div className="profile_data">
                      <h2> Bloger Name</h2>
                      <p> 11-March-2022 08 min read</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* {related.map((curElem) => {
                return (
                  <>
                    <div className="col-4 blog_detail_cards_mobile gx-5">
                      <div className="blog_detail_img_cards">
                        <figure>
                          <img src={curElem.img} alt="img" />
                        </figure>
                        <h3 className="mt-2"> {curElem.blog} </h3>
                        <h2 className="blog_detail_cards_heading">
                          {curElem.heading}
                        </h2>
                        <p>{curElem.desc}</p>
                        <div className="blog_profile_related d-flex mt-2">
                          <figure>
                            <img
                              src={curElem.profile}
                              alt="img"
                              className="w-50"
                            />
                          </figure>
                          <div className="profile_data">
                            <h2> {curElem.name} </h2>
                            <p> {curElem.date} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })} */}
            </div>
          </div>
        </div>
      </section>
      {/* related content section */}
    </>
  );
};

export default Blogs_detail_page;
