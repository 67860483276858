import React from 'react'
import "../Styles/Team.css";

const Team = ({ImgSrc, Name, position}) => {
  return (
    <div
      className=" team_img_mobile h-[500] w-[20rem] "
      // data-aos="fade-down-right"
      data-aos="fade-down-left"
    >
      <div className="team_images relative" data-aos="fade-down-left">
        <img src={ImgSrc} className="img-fluid " alt="img" />
      </div>
      <div
        className="h-[6.3rem] w-[20rem] bg-[#FFFFFF] name_position absolute"
        data-aos="fade-down-right"
      >
        <p className="name">{Name}</p>
        <p className="title">{position}</p>
      </div>
    </div>
  );
}

export default Team