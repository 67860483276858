import React, { useState } from "react";
import footerphone from "../Images/footerphone.png";
import logo from "../Images/Logo.png";
import email from "../Images/email.png";
import location from "../Images/Location.png";
import "../Styles/Footer.css";
import facebook from "../Images/Facebook.png";
import instagram from "../Images/instagram.png";
import linkedIn from "../Images/Linkedin.png";
import google from "../Images/Google+.png";
import twitter from "../Images/Twitter.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const [product1, setProduct1] = useState(true);
  const [product2, setProduct2] = useState(false);
  const [product3, setProduct3] = useState(false);

  return (
    <>
      <section className="loaction_phone_message d-flex justify-content-center align-items-center w-100">
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-lg-4 col-12 gx-0">
              <div className="phone d-flex align-items-center">
                <div className="phone_image">
                  <figure>
                    <img
                      src={footerphone}
                      alt="img"
                      className="img-fluid w-75"
                    />
                  </figure>
                </div>
                <div className="phone_data ">
                  <p>Call Our Support Team</p>
                  <p className="fw-bold">+92-317-7003000</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 gx-0">
              <div className="message d-flex align-items-center">
                <div className="message_image">
                  <figure>
                    <img src={email} alt="img" className="img-fluid w-75" />
                  </figure>
                </div>
                <div className="message_data ">
                  <p>Send us on Email</p>
                  <p className="fw-bold">info@greenwendenergy.com</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 gx-0">
              <div className="location d-flex align-items-center ">
                <div className="location_image">
                  <figure>
                    <img src={location} alt="img" className="img-fluid w-75" />
                  </figure>
                </div>
                <div className="location_data ">
                  <p>
                    office# 2, 2nd floor, Khyber Heights
                    <span className="fw-bold">
                      <br /> Boards Bazar, Peshawar, Pakistan
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="d-flex justify-content-center align-items-center">
        <div className="container-fluid mt-10">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-12 ">
              <figure>
                <img
                  src={logo}
                  alt="img"
                  className="img-fluid w-75 logo_footer_img"
                />
              </figure>
              <div className="footer_para mt-16">
                <p>
                  GreenWendEnery is your trusted partner for innovative and
                  smart energy solutions across Pakistan. GreenWend Energy has
                  been focused on thier customer’s success. This single focus
                  has enabled us to constantly improve our internal processes to
                  meet and exceed our customers’ expectations.
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-3 offset-lg-1 ">
              <div className="footer_heading">
                <p>Useful Links</p>
              </div>
              <div className="footer_lists">
                <li>
                  <button>Latest News</button>
                </li>
                <li
                  onClick={() => navigate("/blog")}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  <button> New Blogs</button>
                </li>
                <li
                  onClick={() => navigate("/products")}
                  style={{ cursor: "pointer" }}
                >
                  <button> New Products</button>
                </li>
                <li>
                  <button>Get Quotes</button>
                </li>
              </div>
            </div>
            <div className="col-lg-3 col-4">
              <div className="footer_products ml-10">
                <div className="footer_heading">
                  <p>Products</p>
                </div>
                <div className="footer_lists">
                  <li>
                    <button
                      onClick={() => {
                        navigate("/products");
                        setProduct1(true);
                        setProduct2(false);
                        setProduct3(false);
                      }}
                    >
                      Inverter/UPS
                    </button>
                  </li>
                  <li>
                    {" "}
                    <button
                      onClick={() => {
                        navigate("/products");
                        setProduct1(false);
                        setProduct2(true);
                        setProduct3(false);
                      }}
                    >
                      Batteries
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        navigate("/products");
                        setProduct1(false);
                        setProduct2(false);
                        setProduct3(true);
                      }}
                    >
                      Solar Panels
                    </button>
                  </li>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4  col-4 ">
              <div className="footer_heading">
                <p>Get Updates</p>
              </div>
              <div className="footer_lists">
                <p>
                  Subscribe to recieve weekly updated news and special offers.
                </p>
              </div>
              <div className="main ">
                {/* <FaMailBulk className='iconImage'/> */}
                <img className="iconImage" src={email} alt="img" />
                <input
                  type="email"
                  className="input"
                  placeholder="Enter your email"
                />
              </div>
            </div>
            <hr className="mt-5 mb-4 " />

            {/* footer down section */}
            <div className="footer_icons flex m-auto mt-10 justify-content-between align-items-center">
              <div className="flex justify-content-between align-items-center">
                <div className="img">
                  <a
                    href="https://www.facebook.com/greenwend2019/"
                    target="_blank"
                  >
                    {" "}
                    <i class="fab fa-4x fa-facebook-square"></i>
                    {/* <img src={facebook} alt="img" className="imgs" /> */}
                  </a>
                  <a href="#" target="_blank">
                    <i class="fab fa-4x fa-instagram-square"></i>
                    {/* <img src={instagram} alt="img" className="imgs" /> */}
                  </a>
                  <a href="#" target="_blank">
                    <i class="fab fa-4x fa-twitter-square"></i>
                    {/* <img src={twitter} alt="img" className="imgs" /> */}
                  </a>
                  <a
                    href="https://www.linkedin.com/company/greenwend-energy?originalSubdomain=pk"
                    target="_blank"
                  >
                    <i class="fab fa-4x fa-linkedin"></i>
                    {/* <img src={linkedIn} alt="img" className="imgs" /> */}
                  </a>
                  {/* <div className="mobile_view">
                    <li>facebook&nbsp;&nbsp;&nbsp;| </li>
                    <li>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;instagram&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|{" "}
                    </li>
                    <li>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;twitter&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|{" "}
                    </li>
                    <li>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;linkedIn&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|{" "}
                    </li>
                    <li>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;google+{" "}
                    </li>
                  </div> */}
                </div>
              </div>

              <div className="last_line ">
                <h2>
                  Copyright 2022 GreenWendEnergy (Pvt) Ltd&nbsp;&nbsp;
                  <span className="line">|</span> &nbsp; Privacy
                  Policy&nbsp;&nbsp; <span className="line">|</span>
                  &nbsp; Terms and Conditions
                </h2>
              </div>
            </div>

            {/* footer down section */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
